import React, { Fragment } from "react";
import SignUp from "../../components/signup/SignUp";

const SignupPage = () => {
  return (
    <Fragment>
         <SignUp />
    </Fragment>
    );
}
export default  SignupPage;