import React, { Fragment } from "react";
import ForgetPassword from "../../components/forgetPassword/ForgetPassword";


const ForgetPasswordPage = () => {
  return (
    <Fragment>
        <ForgetPassword />
    </Fragment>
    );
}
export default ForgetPasswordPage;