import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ExcelRenderer } from "react-excel-renderer";
import Modal from "react-modal";
import {
  onAddingNewUser,
  onChangeAddUserInput,
  onChangeSearchVal,
  onDeletingUser,
  onEditingUser,
  onFetchingUsers,
  onResetNewUserForm,
  onSearchingUser,
} from "../../store/AddUsers/AddUsers";
import useOrganizations from "../../hooks/useOrganizations";

import { PropagateLoader } from "react-spinners";
import { css } from "@emotion/react";
import { toast, ToastContainer } from "react-toastify";
import { notAuthError, url } from "../../constants";
import useThirdParty from "../../hooks/thirdParty/useThirdParty";
import roles, { isHeigherPermission, notSuperAdminOrSaasAdmin } from "../../util/roles";

import UsersTable from "./Userstable";

import styles from "./users.module.css";
import log from "../../assets/images/log.png";
import "react-toastify/dist/ReactToastify.css";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "20px",
    zIndex: 1000,
  },
};
export default function Users() {
  const [addFileIsOpen, setAddFileIsOpen] = React.useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const dispatch = useDispatch();
  const { token, role } = useSelector(state => state.login);
  const loggedInOrganization = localStorage.getItem("org_id");
  const [uploadOrganization, setUploadOrganization] = useState(loggedInOrganization ? loggedInOrganization : null);
  const loggedInThirdParty = localStorage.getItem("thirdParty_id");
  const { userForm, loading, users, selectedUser, loadFetching, loadEditing, searchResult, searchForm, renderedItem } =
    useSelector(state => state.newUser);

  const { loading: organizationsLoading, organizations } = useOrganizations();
  const [selectedOrg, setSelectedOrg] = useState("");
  const { loading: thirdPartiesLoading, thirdParties } = useThirdParty(selectedOrg);

  const [addIsOpen, setAddIsOpen] = useState(false);
  const [editIsOpen, setEditIsOpen] = useState(false);
  const [DeleteIsOpen, setDeleteIsOpen] = useState(false);
  const [upgradeModalIsOpen, setUpgradeModalIsOpen] = useState(false);
  const [userID, setUserID] = useState("");
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    dispatch(onFetchingUsers(token));
  }, [dispatch, token, flag]);

  const thirdPartyId = localStorage.getItem("thirdParty_id");
  let filteredUsers = users;
  let filteredSearchResult = searchResult;
  if (thirdPartyId) {
    filteredUsers = users.filter(user => user.thirdParty_id === thirdPartyId);
    filteredSearchResult = searchResult.filter(result => result.thirdParty_id === thirdPartyId);
  }

  useEffect(() => {
    if (selectedUser && selectedUser.fullName) {
      let firstSpaceIndex = selectedUser.fullName.indexOf(" ");
      dispatch(
        onChangeAddUserInput(
          selectedUser.fullName.indexOf(" ") > -1
            ? selectedUser.fullName.substring(0, firstSpaceIndex)
            : selectedUser.fullName,
          "firstName"
        )
      );
      dispatch(
        onChangeAddUserInput(
          selectedUser.fullName.split(" ")[1] ? selectedUser.fullName.substring(firstSpaceIndex + 1) : "",
          "lastName"
        )
      );
      dispatch(onChangeAddUserInput(selectedUser.email, "email"));
      dispatch(onChangeAddUserInput(selectedUser.role, "role"));
      dispatch(onChangeAddUserInput(selectedUser.org_id, "org_id"));
      dispatch(onChangeAddUserInput(selectedUser.thirdParty_id, "thirdParty_id"));
      dispatch(onChangeAddUserInput(!!selectedUser.thirdParty_id, "hasThirdParty"));
      setSelectedOrg(selectedUser.org_id);
    }
  }, [dispatch, selectedUser]);

  const handleUpgrade = id => {
    setUpgradeModalIsOpen(true);
  };

  const [state, setState] = useState();
  const [file, setFile] = useState({});
  const fileHandler = event => {
    let fileObj = event.target.files[0];

    setFile(fileObj);
    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        setState({
          cols: resp.cols,
          rows: resp.rows,
        });
      }
    });
  };

  const [generatePassword, setGeneratePassword] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [isUploadThirdParty, setIsUploadThirdParty] = useState(false);
  const [uploadThirdParty, setUploadThirdParty] = useState(loggedInThirdParty ? loggedInThirdParty : null);

  const onUploadFile = e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("userFile", file);

    fetch(
      `${url}/api/users/upload/${uploadOrganization}?generatePassword=${generatePassword}&sendEmail=${sendEmail}${
        isUploadThirdParty ? `&thirdParty_id=${uploadThirdParty}` : ""
      }`,
      {
        method: "POST",
        headers: { Authorization: `Bearer ${token}`, web: true },
        body: formData,
      }
    )
      .then(res => res.json())
      .then(resData => {
        if (resData.error) {
          if (resData.error === notAuthError) {
            toast("you session expired, please login again.");
          } else {
            const error = resData.error;
            if (typeof error === "string") {
              toast.error(resData.error);
            } else if (typeof Object.entries(resData.error).map(([k, v]) => v)[0] === "string") {
              toast.error(Object.entries(resData.error).map(([k, v]) => v)[0]);
            } else {
              toast.error(Object.entries(resData.error).map(([k, v]) => v)[0][0]);
            }
          }
        } else {
          if (resData.message) {
            toast.success(resData.message);
            setFlag(true);
          }
        }

        setFlag(false);
      });
  };
  return (
    <Fragment>
      <div className="container">
        <ToastContainer />
        <div className="row">
          <div className="col-12 mt-4 m-auto row">
            <div className="my-4 col-6 fs-3 font-semibold text-uppercase">Users</div>
            {/* {notSuperUser.includes(role) && ( */}
            <div className="row col-6 d-flex justify-content-end my-4">
              <div className="col-6 col-md-4 w-100 d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-primary me-2"
                  onClick={() => {
                    setAddFileIsOpen(true);
                  }}
                >
                  Import Users
                </button>
                <button
                  type="button"
                  className="btn btn-primary py-3"
                  onClick={() => {
                    setAddIsOpen(true);
                    dispatch(onResetNewUserForm());
                  }}
                >
                  Add User
                </button>
              </div>
            </div>
            {/* )} */}
            <div className="row m-auto">
              <div className="col-8 d-flex justify-content-end  my-4" style={{ paddingRight: "0px" }}>
                <div style={{ position: "relative", width: "100%" }}>
                  <i className="far fa-search text-dark" style={{ position: "absolute", top: "30%", left: "1%" }}></i>
                  <input
                    style={{ paddingLeft: 35 }}
                    type="text"
                    className="form-control border-1 py-2 border-black rounded-3"
                    name="search"
                    placeholder="Search"
                    onChange={e => {
                      dispatch(onChangeSearchVal(e.target.value, "textVal"));
                      dispatch(onSearchingUser(e.target.value, token, searchForm.searchType.value));
                    }}
                  />
                </div>
              </div>
              <div className="col-4 d-flex justify-content-end  my-4">
                <select
                  id="select"
                  className="form-select"
                  onChange={e => {
                    dispatch(onChangeSearchVal(e.target.value, "searchType"));
                    dispatch(onSearchingUser(searchForm.textVal.value, token, e.target.value));
                  }}
                  // style={{ width: "70%" }}
                >
                  <option value="">Filter By</option>
                  <option value="fullName">Name</option>
                  <option value="email">Email</option>
                </select>
              </div>
            </div>

            {renderedItem === "users" ? (
              loadFetching || organizationsLoading ? (
                <div style={{ textAlign: "center", padding: "20px 0" }}>
                  <div className="spinner-border" role="status">
                    {/* <span className="sr-only">Loading...</span> */}
                  </div>
                </div>
              ) : (
                <UsersTable
                  users={filteredUsers}
                  handleUpgrade={handleUpgrade}
                  log={log}
                  setEditIsOpen={setEditIsOpen}
                  setAddIsOpen={setAddIsOpen}
                  setDeleteIsOpen={setDeleteIsOpen}
                  setUserID={setUserID}
                  setFlag={setFlag}
                />
              )
            ) : (
              <UsersTable
                users={filteredSearchResult}
                handleUpgrade={handleUpgrade}
                log={log}
                setEditIsOpen={setEditIsOpen}
                setAddIsOpen={setAddIsOpen}
                setDeleteIsOpen={setDeleteIsOpen}
                setUserID={setUserID}
                setFlag={setFlag}
              />
            )}
          </div>
        </div>
        {/* Modal ADD User */}
        <Modal
          isOpen={addIsOpen}
          style={customStyles}
          onRequestClose={() => setAddIsOpen(false)}
          contentLabel="Example Modal"
          className={styles.Modal}
          overlayClassName={styles.Overlay}
          ariaHideApp={false}
        >
          <div className="container modal-body ">
            <div className="row">
              <div className="col-10 m-auto">
                <h5 className="text-center my-3">ADD NEW USER</h5>
                <form
                  onSubmit={e => {
                    dispatch(
                      onAddingNewUser({
                        e,
                        token: token,
                        lastName: userForm.lastName.value,
                        firstName: userForm.firstName.value,
                        email: userForm.email.value,
                        password: userForm.password.value,
                        role: userForm.role.value,
                        org_id: userForm.org_id.value,
                        hasThirdParty: loggedInThirdParty ? true : userForm.hasThirdParty.value,
                        thirdParty_id: loggedInThirdParty ? loggedInThirdParty : userForm.thirdParty_id.value,
                        userRole: role,
                      })
                    );
                    setAddIsOpen(false);
                  }}
                >
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control p-2"
                      placeholder="First Name"
                      onChange={e => dispatch(onChangeAddUserInput(e.target.value, "firstName"))}
                      value={userForm.firstName.value}
                      style={userForm.firstName.valid ? { borderColor: "#ccc" } : { borderColor: "red" }}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      onChange={e => dispatch(onChangeAddUserInput(e.target.value, "lastName"))}
                      value={userForm.lastName.value}
                      style={userForm.lastName.valid ? { borderColor: "#ccc" } : { borderColor: "red" }}
                    />
                  </div>
                  {userForm.firstName.valid ? null : (
                    <div style={{ color: "red", fontSize: 14 }}>{userForm.firstName.validationError}</div>
                  )}
                  <input
                    type="email"
                    className="form-control mt-3 p-2"
                    placeholder="Email"
                    name="email"
                    onChange={e => dispatch(onChangeAddUserInput(e.target.value, "email"))}
                    value={userForm.email.value}
                    style={userForm.email.valid ? { borderColor: "#ccc" } : { borderColor: "red" }}
                  />
                  {userForm.email.valid ? null : (
                    <div style={{ color: "red", fontSize: 14 }}>{userForm.email.validationError}</div>
                  )}
                  <input
                    type="password"
                    className="form-control mt-3 p-2"
                    placeholder="Password"
                    name="password"
                    onChange={e => dispatch(onChangeAddUserInput(e.target.value, "password"))}
                    value={userForm.password.value}
                    style={userForm.password.valid ? { borderColor: "#ccc" } : { borderColor: "red" }}
                  />
                  {userForm.password.valid ? null : (
                    <div style={{ color: "red", fontSize: 14 }}>{userForm.password.validationError}</div>
                  )}
                  <select
                    id="select"
                    className="form-select mt-3 p-2"
                    onChange={e => {
                      dispatch(onChangeAddUserInput(e.target.value, "role"));
                      if (e.target.value === "saas admin") {
                        dispatch(onChangeAddUserInput("not Empty", "org_id"));
                      }
                      // if (e.target.value === "third party") {
                      //   dispatch(onChangeAddUserInput(true, "hasThirdParty"));
                      // } else {
                      //   dispatch(onChangeAddUserInput(false, "hasThirdParty"));
                      // }
                    }}
                    defaultValue={userForm.role.value}
                    style={userForm.role.valid ? { borderColor: "#ccc" } : { borderColor: "red" }}
                  >
                    <option disabled value="">
                      Choose role...
                    </option>
                    {role === roles.saas_admin && <option value="saas admin">SAAS ADMIN</option>}
                    {role === roles.saas_admin && <option value="super admin">SUPER ADMIN</option>}
                    {(role === roles.saas_admin || role === roles.super_admin) && <option value="admin">ADMIN</option>}
                    {(role === roles.saas_admin || role === roles.super_admin || role === roles.admin) && (
                      <option value="super user">SUPER USER</option>
                    )}
                    <option value="user">USER</option>
                  </select>
                  {userForm.role.valid ? null : (
                    <div style={{ color: "red", fontSize: 14 }}>{userForm.role.validationError}</div>
                  )}
                  <>
                    {userForm.role.value !== "saas admin" && organizations && role === roles.saas_admin && (
                      <>
                        <select
                          id="select1"
                          className="form-select mt-3 p-2"
                          onChange={e => {
                            dispatch(onChangeAddUserInput(e.target.value, "org_id"));
                            setSelectedOrg(e.target.value);
                          }}
                          value={selectedUser && userForm.role && userForm.org_id.value}
                          style={userForm.org_id.valid ? { borderColor: "#ccc" } : { borderColor: "red" }}
                        >
                          <option disabled value="">
                            Organization
                          </option>
                          {organizations &&
                            organizations.map((organization, i) => (
                              <option key={i} value={organization.id}>
                                {organization.name}
                              </option>
                            ))}
                        </select>

                        {userForm.org_id.valid ? null : (
                          <div style={{ color: "red", fontSize: 14 }}>{userForm.org_id.validationError}</div>
                        )}
                      </>
                    )}
                  </>

                  {!notSuperAdminOrSaasAdmin.includes(role) && (
                    <>
                      <div className="flex items-center mt-2">
                        <input
                          // disabled={userForm.role.value === "third party"}
                          onChange={e => {
                            if (userForm.hasThirdParty.value) {
                              //value then name
                              dispatch(onChangeAddUserInput(false, "hasThirdParty"));
                            } else {
                              dispatch(onChangeAddUserInput(true, "hasThirdParty"));
                            }
                          }}
                          checked={userForm.hasThirdParty.value}
                          id="checked-checkbox"
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label for="checked-checkbox" className="ml-2 text-sm font-medium">
                          has third party
                        </label>
                      </div>
                      {userForm.hasThirdParty.value && (
                        <>
                          <select
                            className="form-select mt-3 p-2"
                            onChange={e => dispatch(onChangeAddUserInput(e.target.value, "thirdParty_id"))}
                            value={userForm.thirdParty_id.value}
                            style={userForm.thirdParty_id.valid ? { borderColor: "#ccc" } : { borderColor: "red" }}
                          >
                            <option disabled value="">
                              select third party
                            </option>
                            {!thirdPartiesLoading && thirdParties.length ? (
                              thirdParties.map(thirdParty => <option value={thirdParty.id}>{thirdParty.name}</option>)
                            ) : (
                              <option value="disabled" disabled>
                                no third party was found
                              </option>
                            )}
                          </select>

                          {userForm.thirdParty_id.valid ? null : (
                            <div style={{ color: "red", fontSize: 14 }}>{userForm.thirdParty_id.validationError}</div>
                          )}
                        </>
                      )}
                    </>
                  )}
                  <div className="d-flex justify-content-center my-3">
                    <button
                      disabled={
                        disableBtn
                          ? disableBtn
                          : userForm.role.value === roles.saas_admin || role !== roles.saas_admin
                          ? !(
                              userForm.lastName.valid &&
                              userForm.firstName.valid &&
                              userForm.email.valid &&
                              userForm.role.valid &&
                              (!userForm.hasThirdParty.value || userForm.thirdParty_id.valid)
                            )
                          : !(
                              userForm.lastName.valid &&
                              userForm.firstName.valid &&
                              userForm.email.valid &&
                              userForm.role.valid &&
                              userForm.org_id.valid &&
                              (!userForm.hasThirdParty.value || userForm.thirdParty_id.valid)
                            )
                      }
                      type="submit"
                      className="btn btn-primary w-25"
                      style={{ width: "130px", height: "40px" }}
                      onClick={() => {
                        setTimeout(() => {
                          setDisableBtn(true);
                        }, 1);
                        setTimeout(() => {
                          setDisableBtn(false);
                        }, 2000);
                      }}
                    >
                      {/* ADD */}
                      {loading ? <PropagateLoader color={"#fff"} css={override} size={10} /> : "ADD"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal>
        {/* End Model */}

        {/* Edit User Modal */}
        <Modal
          isOpen={editIsOpen}
          style={customStyles}
          onRequestClose={() => setEditIsOpen(false)}
          contentLabel="Edit Modal"
          ariaHideApp={false}
          className={styles.Modal}
          overlayClassName={styles.Overlay}
        >
          <div className="container modal-body">
            <div className="row">
              <div className="col-10 m-auto">
                <h5 className="text-center my-3">EDIT NEW USER</h5>
                <form
                  onSubmit={e => {
                    dispatch(
                      onEditingUser({
                        e,
                        userId: selectedUser.user_id,
                        token: token,
                        lastName: userForm.lastName.value,
                        firstName: userForm.firstName.value,
                        email: userForm.email.value,
                        password: userForm.password.value,
                        confirmPassword: userForm.confirmPassword.value,
                        role: userForm.role.value,
                        org_id: userForm.org_id.value,
                        hasThirdParty: userForm.hasThirdParty.value,
                        thirdParty_id: userForm.thirdParty_id.value,
                      })
                    );
                    setEditIsOpen(false);
                  }}
                >
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control p-2"
                      placeholder="First Name"
                      onChange={e => dispatch(onChangeAddUserInput(e.target.value, "firstName"))}
                      value={userForm.firstName.value}
                      style={userForm.firstName.valid ? { borderColor: "#ccc" } : { borderColor: "red" }}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      onChange={e => dispatch(onChangeAddUserInput(e.target.value, "lastName"))}
                      value={selectedUser && userForm.lastName && userForm.lastName.value}
                      style={userForm.lastName.valid ? { borderColor: "#ccc" } : { borderColor: "red" }}
                    />
                  </div>
                  {userForm.firstName.valid ? null : (
                    <div style={{ color: "red", fontSize: 14 }}>{userForm.firstName.validationError}</div>
                  )}

                  <input
                    type="email"
                    className="form-control mt-3 p-2"
                    placeholder="Email"
                    name="email"
                    onChange={e => dispatch(onChangeAddUserInput(e.target.value, "email"))}
                    value={selectedUser && userForm.email && userForm.email.value}
                    style={userForm.email.valid ? { borderColor: "#ccc" } : { borderColor: "red" }}
                  />
                  {userForm.email.valid ? null : (
                    <div style={{ color: "red", fontSize: 14 }}>{userForm.email.validationError}</div>
                  )}

                  <input
                    type="password"
                    className="form-control mt-3 p-2"
                    placeholder="Password"
                    name="password"
                    onChange={e => dispatch(onChangeAddUserInput(e.target.value, "password"))}
                    value={selectedUser && userForm.password && userForm.password.value}
                    style={{ borderColor: "#ccc" }}
                  />

                  <input
                    type="password"
                    className="form-control mt-3 p-2"
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    onChange={e => dispatch(onChangeAddUserInput(e.target.value, "confirmPassword"))}
                    value={selectedUser && userForm.confirmPassword && userForm.confirmPassword.value}
                    style={{ borderColor: "#ccc" }}
                  />
                  {userForm.role.value !== "super admin" && (
                    <select
                      id="select1"
                      className="form-select mt-3 p-2"
                      onChange={e => {
                        dispatch(onChangeAddUserInput(e.target.value, "role"));
                        if (e.target.value === "saas admin") {
                          dispatch(onChangeAddUserInput("not Empty", "org_id"));
                        }
                      }}
                      value={selectedUser && userForm.role && userForm.role.value}
                      style={userForm.role.valid ? { borderColor: "#ccc" } : { borderColor: "red" }}
                    >
                      <option disabled value="">
                        Role
                      </option>
                      {role === roles.saas_admin && <option value="saas admin">SAAS ADMIN</option>}
                      {isHeigherPermission(role, "admin") && <option value="admin">ADMIN</option>}
                      <option value="super user">SUPER USER</option>
                      <option value="user">USER</option>
                    </select>
                  )}
                  {userForm.role.valid ? null : (
                    <div style={{ color: "red", fontSize: 14 }}>{userForm.role.validationError}</div>
                  )}
                  {userForm.role.value !== "saas admin" && organizations && role === roles.saas_admin && (
                    <>
                      <select
                        id="select1"
                        className="form-select mt-3 p-2"
                        onChange={e => {
                          dispatch(onChangeAddUserInput(e.target.value, "org_id"));
                          setSelectedOrg(e.target.value);
                        }}
                        value={selectedUser && userForm.role && userForm.org_id.value}
                        style={userForm.org_id.valid ? { borderColor: "#ccc" } : { borderColor: "red" }}
                      >
                        <option disabled value="">
                          Organization
                        </option>
                        {organizations &&
                          organizations.map((organization, i) => (
                            <option key={i} value={organization.id}>
                              {organization.name}
                            </option>
                          ))}
                      </select>
                      {userForm.org_id.valid ? null : (
                        <div style={{ color: "red", fontSize: 14 }}>{userForm.org_id.validationError}</div>
                      )}
                    </>
                  )}

                  {!notSuperAdminOrSaasAdmin.includes(role) && (
                    <>
                      <div className="flex items-center mt-2">
                        <input
                          onChange={e => {
                            if (userForm.hasThirdParty.value) {
                              //value then name
                              dispatch(onChangeAddUserInput(false, "hasThirdParty"));
                            } else {
                              dispatch(onChangeAddUserInput(true, "hasThirdParty"));
                            }
                          }}
                          checked={userForm.hasThirdParty.value}
                          id="checked-checkbox"
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label for="checked-checkbox" className="ml-2 text-sm font-medium">
                          has third party
                        </label>
                      </div>
                      {userForm.hasThirdParty.value && (
                        <>
                          <select
                            className="form-select mt-3 p-2"
                            onChange={e => dispatch(onChangeAddUserInput(e.target.value, "thirdParty_id"))}
                            value={userForm.thirdParty_id.value}
                            style={userForm.thirdParty_id.valid ? { borderColor: "#ccc" } : { borderColor: "red" }}
                          >
                            <option disabled value="">
                              select third party
                            </option>
                            {!thirdPartiesLoading && thirdParties.length ? (
                              thirdParties.map(thirdParty => <option value={thirdParty.id}>{thirdParty.name}</option>)
                            ) : (
                              <option value="disabled" disabled>
                                no third party was found
                              </option>
                            )}
                          </select>

                          {userForm.thirdParty_id.valid ? null : (
                            <div style={{ color: "red", fontSize: 14 }}>{userForm.thirdParty_id.validationError}</div>
                          )}
                        </>
                      )}
                    </>
                  )}

                  <div className="d-flex justify-content-center my-3">
                    <button
                      disabled={
                        disableBtn
                          ? disableBtn
                          : userForm.role.value === roles.saas_admin || role !== roles.saas_admin
                          ? !(
                              userForm.lastName.valid &&
                              userForm.firstName.valid &&
                              userForm.email.valid &&
                              userForm.role.valid &&
                              (!userForm.hasThirdParty.value || userForm.thirdParty_id.valid)
                            )
                          : !(
                              userForm.lastName.valid &&
                              userForm.firstName.valid &&
                              userForm.email.valid &&
                              userForm.role.valid &&
                              userForm.org_id.valid &&
                              (!userForm.hasThirdParty.value || userForm.thirdParty_id.valid)
                            )
                      }
                      type="submit"
                      className="btn btn-primary mx-2 w-25"
                      style={{ width: "130px", height: "40px" }}
                      onClick={() => {
                        setTimeout(() => {
                          setDisableBtn(true);
                        }, 1);
                        setTimeout(() => {
                          setDisableBtn(false);
                        }, 2000);
                      }}
                    >
                      {/* Edit */}
                      {loadEditing ? <PropagateLoader color={"#fff"} css={override} size={10} /> : "Save"}
                    </button>
                    <button type="button" className="btn btn-secondary mx-2" onClick={() => setEditIsOpen(false)}>
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal>
        {/* End Edit User Modal */}

        {/* Delete Modal */}
        <Modal
          isOpen={DeleteIsOpen}
          style={customStyles}
          onRequestClose={() => setDeleteIsOpen(false)}
          contentLabel="Edit Modal"
          ariaHideApp={false}
          className={styles.Modal}
          overlayClassName={styles.Overlay}
        >
          <div className="container modal-body">
            <div className="row">
              <div className="col-10 m-auto">
                <h5 className="text-center my-3">Are you sure you want to delete this?</h5>

                <div className="d-flex justify-content-center my-3">
                  <button
                    type="button"
                    className="btn btn-danger mx-2"
                    onClick={e => {
                      dispatch(onDeletingUser(e, userID, token));
                      setDeleteIsOpen(false);
                    }}
                  >
                    Delete
                  </button>
                  <button type="button" className="btn btn-secondary mx-2" onClick={() => setDeleteIsOpen(false)}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {/* End Delete Modal */}
        {/* Model */}
        <Modal
          isOpen={addFileIsOpen}
          style={customStyles}
          onRequestClose={() => setAddFileIsOpen(false)}
          contentLabel="Add Modal"
          ariaHideApp={false}
          className={styles.Modal}
          overlayClassName={styles.Overlay}
        >
          <div className="modal-body">
            <div className="row">
              <div className="text-end pt-2 pe-3">
                <a href="./files/User_Data_Template.xlsx" download>
                  <button className="btn btn-primary">Download Template</button>
                </a>
              </div>
              <form
                onSubmit={e => {
                  onUploadFile(e);
                  setAddFileIsOpen(false);
                  setGeneratePassword(false);
                  setSendEmail(false);
                  setIsUploadThirdParty(false);
                  setUploadThirdParty(loggedInThirdParty ? loggedInThirdParty : null);
                  setFile({});
                }}
              >
                <div className="col">
                  <h3 className="text-center mt-5 text-primary">IMPORT USER INFO</h3>
                  <div className="d-flex justify-content-center">{file && file.name}</div>
                  <div className="d-flex justify-content-center">
                    <label className="btn btn-outline-primary btn-sm my-3">
                      <input type="file" style={{ display: "none" }} onChange={e => fileHandler(e)} />
                      Choose File
                    </label>
                  </div>
                  <p className="text-center">Only .xlsx files can be uploaded</p>
                  {role === "saas admin" && (
                    <div className="border rounded-1 border-gray m-4 p-3">
                      <h5 className="text-center">Organization *</h5>
                      <select
                        id="selectOrganization"
                        className="form-select mt-3 p-2"
                        onChange={e => {
                          setUploadOrganization(e.target.value);
                        }}
                        value={uploadOrganization}
                      >
                        <option disabled selected value={null}>
                          select organization
                        </option>
                        {organizations &&
                          organizations.map((organization, i) => (
                            <option key={i} value={organization.id}>
                              {organization.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  )}
                  {!loggedInThirdParty && (
                    <div className="row justify-center border rounded-1 border-gray m-4 py-3">
                      <h5 className="text-center">Third Party</h5>
                      <div className="col-4 py-2 ps-5 items-center">
                        <input
                          onChange={e => {
                            setIsUploadThirdParty(!isUploadThirdParty);
                          }}
                          checked={isUploadThirdParty}
                          type="checkbox"
                        />
                        <label for="checked-checkbox" className="ml-2 text-sm font-medium">
                          Has third party
                        </label>
                      </div>
                      <div className="col-8 text-center">
                        {isUploadThirdParty && (
                          <select
                            className="form-select p-2"
                            onChange={e => {
                              setUploadThirdParty(e.target.value);
                            }}
                            value={uploadThirdParty}
                          >
                            <option disabled selected value={null}>
                              select third party
                            </option>
                            {!thirdPartiesLoading && thirdParties.length ? (
                              thirdParties.map(thirdParty => <option value={thirdParty.id}>{thirdParty.name}</option>)
                            ) : (
                              <option value="disabled" disabled>
                                no third party was found
                              </option>
                            )}
                          </select>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="row justify-center border rounded-1 border-gray m-4 py-3">
                    <h5 className="text-center">Options</h5>
                    <div className="col-6 ps-5">
                      <input
                        type="checkbox"
                        id="generatePasswords"
                        className="mx-2"
                        checked={generatePassword}
                        onChange={() => {
                          if (sendEmail === false && generatePassword === false) {
                            setSendEmail(true);
                          }
                          setGeneratePassword(!generatePassword);
                        }}
                      />
                      <label htmlFor={"generatePasswords"}>Generate Passwords</label>
                    </div>
                    <div className="col-6 ps-5">
                      <input
                        type="checkbox"
                        id="sendEmails"
                        className="mx-2"
                        checked={sendEmail}
                        onChange={() => {
                          if (generatePassword === true) {
                            setSendEmail(true);
                          } else {
                            setSendEmail(!sendEmail);
                          }
                        }}
                      />
                      <label htmlFor={"sendEmails"}>Send Emails</label>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center mb-5">
                    <button
                      type="submit"
                      disabled={uploadOrganization === null || file["name"] === undefined ? true : false}
                      className="btn btn-primary btn-sm w-25"
                    >
                      Upload
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal>
        {/* End Model */}
      </div>
    </Fragment>
  );
}
