import React, { Fragment } from "react";
import LogIn from "../../components/login/LogIn";

const UsersPage = () => {
  return (
    <Fragment>
      <LogIn />
    </Fragment>
  );
};
export default UsersPage;
