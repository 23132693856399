import { useState, useEffect } from "react";
import axios from "axios";
import { useFormik } from "formik";
import useThirdParty from "../hooks/thirdParty/useThirdParty";
import validationSchema from "../validations/thirdParty";
import { url } from "../constants";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const useThirdPartiesContainer = () => {
  const { loading, thirdParties: data } = useThirdParty();
  const { token } = useSelector((state) => state.login);

  const [thirdParties, setThirdParties] = useState([]);
  const [searchedThirdParties, setSearchedThirdParties] = useState([]);

  const [openForm, setOpenForm] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [selectedThirdParty, setSelectedThirdParty] = useState({});

  const [initialValues, setInitialValues] = useState({
    name: "",
    org_id:""
  });

  const handleSubmit = async (values) => {
    setSubmitLoading(true);
    try {
      if (isEdit) {
        const result = await axios.patch(
          `${url}/api/thirdparties/${selectedThirdParty?.id}`,
          { name: values.name, org_id:values.org_id },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              web: true,
            },
          }
        );
        let newThirdParties = [...thirdParties];

        const index = thirdParties.findIndex(
          (o) => o.id === selectedThirdParty.id
        );
        newThirdParties.splice(index, 1, result.data.ThirdParty);

        setThirdParties(newThirdParties);
        setSearchedThirdParties(newThirdParties);
        toast(result.data.message);
      } else {
        const result = await axios.post(
          `${url}/api/thirdparties`,
          { name: values.name, org_id:values.org_id },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              web: true,
            },
          }
        );
        setThirdParties([...thirdParties, result.data.ThirdParty]);
        setSearchedThirdParties([...thirdParties, result.data.ThirdParty]);
        toast(result.data.message);
      }

      setOpenForm(false);
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        toast.error(error.response.data.error);
      } else {
        toast.error("something went wrong");
      }
    } finally {
      setSubmitLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: () => validationSchema(isEdit),
    onSubmit: (values, { resetForm }) => {
      handleSubmit(values);
      resetForm();
    },
  });

  useEffect(() => {
    if (data) {
      setThirdParties(data);
      setSearchedThirdParties(data);
    }
  }, [data]);

  const handleOpenCreate = () => {
    setOpenForm(true);
    setIsEdit(false);
    setInitialValues({
      name: "",
      org_id:""
    });
  };

  const handleOpenDelete = (thirdParty) => {
    setOpenDelete(true);
    setSelectedThirdParty(thirdParty);
  };

  const handleOpenEdit = (thirdParty) => {
    setOpenForm(true);
    setIsEdit(true);
    setSelectedThirdParty(thirdParty);
    setInitialValues({ name: thirdParty.name,org_id:thirdParty.org_id });
  };

  const handleDelete = async () => {
    setSubmitLoading(true);
    try {
      const result = await axios.delete(
        `${url}/api/thirdparties/${selectedThirdParty.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            web: true,
          },
        }
      );
      setThirdParties(
        thirdParties.filter((o) => o.id !== selectedThirdParty.id)
      );
      setSearchedThirdParties(
        thirdParties.filter((o) => o.id !== selectedThirdParty.id)
      );
      toast(result.data.message);
      setOpenDelete(false);
    } catch (error) {
      toast(error.message);
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleSearch = (value) => {
    if (value.trim() !== "") {
      const arr = [...thirdParties].filter((o) =>
        o.name.toLowerCase().includes(value.toLowerCase())
      );
      setSearchedThirdParties(arr);
    } else {
      setSearchedThirdParties(thirdParties);
    }
  };
  return {
    thirdParties: searchedThirdParties,
    openDelete,
    setOpenDelete,
    openForm,
    setOpenForm,
    loading,
    formik,
    isEdit,
    handleOpenCreate,
    submitLoading,
    selectedThirdParty,
    handleOpenDelete,
    handleOpenEdit,
    handleDelete,
    handleSearch,
  };
};

export default useThirdPartiesContainer;
