import React from 'react'
import HeroCon from "../../components/hero/hero";


const Hero = () => {
  return (
    <div>
        <HeroCon/>
    </div>
  )
}

export default Hero