import unassignedSingleLoc from "../../../assets/images/single-unassigned.png";
import assignedSingleLoc from "../../../assets/images/single-assigned.png";
import assignedDualLoc from "../../../assets/images/dual-assigned.png";
import unassignedDualLoc from "../../../assets/images/dual-unassigned.png";

const LOCCheckbox = ({ locType, handleSelect, selectedLocTypes }) => {
  const status = locType.split(" - ")[0];
  const type = locType.split(" - ")[1];

  const image =
    type === "single" && status === "unassigned"
      ? unassignedSingleLoc
      : type === "single" && status === "assigned"
      ? assignedSingleLoc
      : type === "dual" && status === "assigned"
      ? assignedDualLoc
      : unassignedDualLoc;

  return (
    <div className="form-group form-check">
      <input
        onClick={() => handleSelect(locType)}
        checked={selectedLocTypes.includes(locType)}
        type="checkbox"
        className="form-check-input"
        id={`loc_checkbox_${locType}`}
      />
      <label className="form-check-label text-capitalize" for={`loc_checkbox_${locType}`} style={{fontSize:'12px'}}>
        {locType}
        <img src={image} alt="img" style={{ marginLeft: 10,width:15,height:15 }} />
      </label>
    </div>
  );
};

export default LOCCheckbox;
