import React, { useState } from "react";

const Tooltip = ({ children, text }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className=" relative inline-block">
      <div onMouseOver={() => setShowTooltip(true)} onMouseOut={() => setShowTooltip(false)}>
        {children}
      </div>
      {showTooltip && (
        <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1  text-white p-2 bg-black rounded-md text-xs text-nowrap z-50 opacity-75">
          {text}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
