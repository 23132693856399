import React, { useEffect, useState } from "react";
import Geocode from "react-geocode";
import { GoogleMap } from "@react-google-maps/api";
import LOCMarker from "./LOCMarker";
import Line from "./Line";

Geocode.setApiKey("AIzaSyBW67TD6RvsTX8LfZ2qhRt0Ghdd2DnJL7w");

const containerStyle = {
  width: "100%",
  height: "calc( 100vh - 53px)",
};

function createConnections(LOCs) {
  const connections = {};

  LOCs.filter((loc) => loc.type === "dual").forEach((loc) => {
    const locationType = loc.id.includes("origin") ? "origin" : "destination";
  
    const coordinates = { latitude: loc.latitude, longitude: loc.longitude };

    if (!connections[loc.loc_id]) {
      connections[loc.loc_id] = { [locationType]: coordinates,status:loc.status };
    } else {
      connections[loc.loc_id][locationType] = coordinates;
    }
  });
  return Object.values(connections);
}

function LOCsMap({ LOCs, info, isMapLoaded,loading,selectedLegends,fields,selectedField }) {


  const connections = createConnections(LOCs);

  const [center, setCenter] = useState({
    lat: 0,
    lng: 0,
  });

  useEffect(() => {
    if (!loading) {
      setCenter({
        lat: info?.latitude,
        lng: info?.longitude,
      });
    }
  }, [loading]);

  const onMarkerDragEnd = (event) => {
    let newLat = event.latLng.lat();
    let newLng = event.latLng.lng();
    setCenter({
      lat: newLat,
      lng: newLng,
    });
  };
  return (
    <div style={{ width: "100%" }}>
      {(!isMapLoaded || loading) && (
        <div
          style={{
            height: "300px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="spinner-border" role="status">
            {/* <span className="sr-only">Loading...</span> */}
          </div>
        </div>
      )}
      {isMapLoaded && !loading && (
        <>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={14}
            options={{
              styles: [
                {
                  featureType: 'poi',
                  elementType: 'labels.icon',
                  stylers: [{ visibility: 'off' }],
                },
              ],
            }}
          >
            {LOCs.map((loc) => (
              <LOCMarker
                key={loc.id}
                loc={loc}
                selectedLegends={selectedLegends}
                fields={fields}
                selectedField={selectedField}
                onMarkerDragEnd={onMarkerDragEnd}
              />
            ))}
            {connections.map((connection) => (
              <Line connection={connection} />
            ))}
          </GoogleMap>
        </>
      )}
    </div>
  );
}

export default LOCsMap;
