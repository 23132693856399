import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";

import { toast, ToastContainer } from "react-toastify";
import fileDownload from "js-file-download";

import { notAuthError, url } from "../../constants";
import LOCsMap from "../LOCsMap";
import { separateOriginFromDest } from "../LOCsMap/utils/separateOriginFromDest";
import LOCsPanel from "../LOCsMap/LOCsPanel";
import { locTypes } from "../../util/constants";
import { useLoadScript } from "@react-google-maps/api";

import {notSaasAdminOrSuperAdminOrSuperUser} from "../../util/roles";
import { ReactComponent as RecycleBinIcon } from "../../assets/images/recycle-bin-empty_svgrepo.com.svg";
import { ReactComponent as ReportIcon } from "../../assets/images/copy-to-clipboard-svgrepo-com.svg";

const styleLinkBack = {
  textDecoration: "none",
  color: "#717993",
  fontSize: "22px",
};
const libraries = ["places"];

const ViewLocs = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const role = useSelector((state) => state.login.role);
  const { token } = useSelector((state) => state.login);

  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBW67TD6RvsTX8LfZ2qhRt0Ghdd2DnJL7w",
    libraries,
  });

  const [globalIdentifier, setGlobalIdenetifier] = useState(null);
  const [project, setProject] = useState(null);
  const [location, setLocation] = useState(null);

  const [locs, setLocs] = useState(10);
  const [assignedLocs, setAssignedLocs] = useState(0);
  const [hidePanel, setHidePanel] = useState(false);
  const [mapData, setMapData] = useState({
    LOCs: [],
    location: {
      gid: "",
      id: "",
      latitude: 0,
      longitude: 0,
    },
  });
  const [selectedLocs, setSelectedLocs] = useState([]);
  const [selectedLocTypes, setSelectedLocTypes] = useState([]);

  const [mapDataLoading, setMapDataLoading] = useState(true);

  const handleSelect = (loc) => {
    const isSelected = selectedLocTypes.includes(loc);

    const selectedList = isSelected
      ? selectedLocTypes.filter((type) => type !== loc)
      : [...selectedLocTypes, loc];

    let filteredList = [];

    selectedList.forEach((item) => {
      let [status, type, place] = item.split(" - ");
      place = place || "";
      const newList = [...mapData.LOCs].filter(
        (obj) =>
          obj.status === status && obj.type === type && obj.place === place
      );

      filteredList = [...filteredList, ...newList];
    });

    setSelectedLocs(filteredList);

    setSelectedLocTypes(selectedList);
  };

  useEffect(() => {
    setMapDataLoading(true);
    fetch(`${url}/api/locations/${id}/get-locs-for-location-map`, {
      headers: { Authorization: `Bearer ${token}`, web: true },
    })
      .then((res) => res.json())
      .then((resData) => {
        if (resData.error) {
          if (resData.error === notAuthError) {
            toast("you session expired, please login again.");
          } else {
            toast.error(resData.error);
          }
          setMapDataLoading(false);
        } else {
          const newLOCs = separateOriginFromDest(resData.LOCs);
          const data = { ...resData, LOCs: newLOCs };

          setMapData(data);
          setSelectedLocs(newLOCs);
          setSelectedLocTypes(locTypes);

          setMapDataLoading(false);
        }
      });
  }, []);

  const dispatch = useDispatch();

  const handleDownload = (type) => {
    fetch(
      `${url}/api/locations/${id}/download-with-type-web?cable_status=${type}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          web: true,
        },
      }
    )
      .then(async (response) => {
        if (response.ok) {
          return response.blob();
        }
        return Promise.reject(
          response.text().then((text) => {
            if (text) {
              toast.error(JSON.parse(text).error);
            } else {
              toast.error("Something went wrong...");
            }
            throw new Error(text);
          })
        );
      })
      .then((blob) => {
        fileDownload(blob, `${location.name}.xlsx`);
      });
  };

  useEffect(() => {
    fetch(`${url}/api/locations/${id}`, {
      headers: { Authorization: `Bearer ${token}`, web: true },
    })
      .then((res) => res.json())
      .then((resData) => {
        if (resData.error) {
          if (resData.error === notAuthError) {
            toast("you session expired, please login again.");
          } else {
            toast.error(resData.error);
            navigate(-1);
          }
        } else {
          setGlobalIdenetifier(resData.globalIdentifier);
          console.log(resData.globalIdentifier);
          setProject(resData.project);
          setLocation(resData.location);
        }
      });
  }, [dispatch]);

  useEffect(() => {
    if (location && location.id) {
      fetch(`${url}/api/locations/${location.id}/locs-statistics`, {
        headers: { Authorization: `Bearer ${token}`, web: true },
      })
        .then((res) => res.json())
        .then((resData) => {
          if (resData.error) {
            if (resData.error === notAuthError) {
              toast("you session expired, please login again.");
            } else {
              toast.error(resData.error);
            }
          } else {
            setLocs(resData.locs);
            setAssignedLocs(resData.assignedLOCs);
          }
        });
    }
  }, [location]);

  return (
    <Fragment>
      <ToastContainer />

      <div className="container">
        {globalIdentifier?.name && project?.name && location?.name ? (
          <Fragment>
          <Link
            to={"/globalidenetifiers"}
            style={styleLinkBack}
          >
            Global Identifiers
          </Link>
          <span className="mx-2" style={{ color: "#28345C" }}>
            <i className="fas fa-chevron-right"></i>
            <i className="fas fa-chevron-right"></i>
          </span>
            <Link
              to={"/globalidenetifiers/projects/" + globalIdentifier.gid}
              style={styleLinkBack}
            >
              {globalIdentifier.name}
            </Link>
            <span className="mx-2" style={{ color: "#28345C" }}>
              <i className="fas fa-chevron-right"></i>
              <i className="fas fa-chevron-right"></i>
            </span>
            <Link to={"/locations/" + project.id} style={styleLinkBack}>
              {project.name}
            </Link>
            <span className="mx-2" style={{ color: "#28345C" }}>
              <i className="fas fa-chevron-right"></i>
              <i className="fas fa-chevron-right"></i>
            </span>
            <Link to={"/viewlocs/" + location.id} style={styleLinkBack}>
              {location.name}
            </Link>
          </Fragment>
        ) : null}

        <div className="row my-5">
          <div className="col-12 m-auto row justify-center">
            <div className="border rounded-lg shadow-md p-3 col-9 me-1">
              <div className="mb-1 text-lg font-medium text-center">
                <span className="text-primary font-bold">{assignedLocs}</span>{" "}
                assigned locs out of{" "}
                <span className="text-primary font-bold">{locs}</span> locs in
                this organization.
              </div>
              <div className="flex gap-2">
                <div className="w-full h-10 bg-gray-200 rounded-full">
                  <div
                    className="h-10 bg-primary rounded-full px-3 font-bold text-white py-2"
                    style={{
                      width: `${Math.ceil((assignedLocs / locs) * 100)}%`,
                    }}
                  >{`${
                    locs === 0 ? 0 : Math.ceil((assignedLocs / locs) * 100)
                  }%`}</div>
                </div>
              </div>
            </div>

            {!notSaasAdminOrSuperAdminOrSuperUser.includes(role) && (
              <Link to={`/viewlocs/${id}/consumption-report`} className="bg-primary row justify-center fw-bold text-white border rounded-lg shadow-md mx-1 p-3 col-1">
                <ReportIcon />
                <p className="m-0 pt-1 px-0 text-center">LOC Report</p>
              </Link>
            )}
            
            {!notSaasAdminOrSuperAdminOrSuperUser.includes(role) && (
              <Link to={`/viewlocs/${id}/recycle-bin`} className="bg-primary row justify-center fw-bold text-white border rounded-lg shadow-md ms-1 p-3 col-1">
                <RecycleBinIcon />
                <p className="m-0 pt-1 px-0 text-center">Recycle Bin</p>
              </Link>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-12 m-auto">
          <div className="d-flex justify-content-center mb-3">
              <Link
                to={"/assignedlocs/" + id}
                className="btn btn-primary p-3 w-50"
                style={{
                  width: "100%",
                  fontSize: 15,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: '84px'
                }}
              >
                <span style={{ width: "80%" }}>VIEW ASSIGNED LOCs</span>
                <div
                  style={{
                    marginLeft: 10,
                    backgroundColor: "#222d58",
                    width: 25,
                    height: 25,
                    borderRadius: 12.5,
                    border: "1px solid #fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <i className="fas fa-check"></i>
                </div>
              </Link>

              <button
                className="btn ml-2"
                onClick={() => handleDownload("assigned")}
              >
                <svg
              xmlns="http://www.w3.org/2000/svg"
              width="52"
              height="52"
              viewBox="0 0 52 52"
              fill="none"
            >
              <path
                d="M49.4 31.2C48.7104 31.2 48.0491 31.4739 47.5615 31.9615C47.0739 32.4491 46.8 33.1104 46.8 33.8V44.2C46.8 44.8896 46.5261 45.5509 46.0385 46.0385C45.5509 46.5261 44.8896 46.8 44.2 46.8H7.8C7.11044 46.8 6.44912 46.5261 5.96152 46.0385C5.47393 45.5509 5.2 44.8896 5.2 44.2V33.8C5.2 33.1104 4.92607 32.4491 4.43848 31.9615C3.95088 31.4739 3.28956 31.2 2.6 31.2C1.91044 31.2 1.24912 31.4739 0.761522 31.9615C0.273928 32.4491 0 33.1104 0 33.8V44.2C0 46.2687 0.821784 48.2526 2.28457 49.7154C3.74735 51.1782 5.73131 52 7.8 52H44.2C46.2687 52 48.2526 51.1782 49.7154 49.7154C51.1782 48.2526 52 46.2687 52 44.2V33.8C52 33.1104 51.7261 32.4491 51.2385 31.9615C50.7509 31.4739 50.0896 31.2 49.4 31.2ZM24.154 35.646C24.4013 35.8827 24.6928 36.0683 25.012 36.192C25.3232 36.3296 25.6597 36.4006 26 36.4006C26.3403 36.4006 26.6768 36.3296 26.988 36.192C27.3072 36.0683 27.5987 35.8827 27.846 35.646L38.246 25.246C38.7356 24.7564 39.0106 24.0924 39.0106 23.4C39.0106 22.7076 38.7356 22.0436 38.246 21.554C37.7564 21.0644 37.0924 20.7894 36.4 20.7894C35.7076 20.7894 35.0436 21.0644 34.554 21.554L28.6 27.534V2.6C28.6 1.91044 28.3261 1.24912 27.8385 0.761522C27.3509 0.273928 26.6896 0 26 0C25.3104 0 24.6491 0.273928 24.1615 0.761522C23.6739 1.24912 23.4 1.91044 23.4 2.6V27.534L17.446 21.554C16.9564 21.0644 16.2924 20.7894 15.6 20.7894C14.9076 20.7894 14.2436 21.0644 13.754 21.554C13.2644 22.0436 12.9894 22.7076 12.9894 23.4C12.9894 24.0924 13.2644 24.7564 13.754 25.246L24.154 35.646Z"
                fill="#212F54"
              />
            </svg>
                {/* <i className="fa fa-download " style={{ fontSize: "40px" }}></i> */}
              </button>
            </div>
            <div className="d-flex justify-content-center">
              <Link
                to={"/unassignedlocs/" + id}
                className="btn btn-success p-3 w-50"
                style={{
                  backgroundColor: "#09B13B",
                  fontSize: 15,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: '84px'
                }}
              >
                <span style={{ width: "80%" }}>VIEW UNASSIGNED LOCS</span>
                <div
                  style={{
                    marginLeft: 10,
                    backgroundColor: "#09B13B",
                    width: 25,
                    height: 25,
                    borderRadius: 12.5,
                    border: "1px solid #fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <i className="fas fa-close"></i>
                </div>
              </Link>

              <button
                className="btn ml-2"
                onClick={() => handleDownload("unassigned")}
              >
                <svg
              xmlns="http://www.w3.org/2000/svg"
              width="52"
              height="52"
              viewBox="0 0 52 52"
              fill="none"
            >
              <path
                d="M49.4 31.2C48.7104 31.2 48.0491 31.4739 47.5615 31.9615C47.0739 32.4491 46.8 33.1104 46.8 33.8V44.2C46.8 44.8896 46.5261 45.5509 46.0385 46.0385C45.5509 46.5261 44.8896 46.8 44.2 46.8H7.8C7.11044 46.8 6.44912 46.5261 5.96152 46.0385C5.47393 45.5509 5.2 44.8896 5.2 44.2V33.8C5.2 33.1104 4.92607 32.4491 4.43848 31.9615C3.95088 31.4739 3.28956 31.2 2.6 31.2C1.91044 31.2 1.24912 31.4739 0.761522 31.9615C0.273928 32.4491 0 33.1104 0 33.8V44.2C0 46.2687 0.821784 48.2526 2.28457 49.7154C3.74735 51.1782 5.73131 52 7.8 52H44.2C46.2687 52 48.2526 51.1782 49.7154 49.7154C51.1782 48.2526 52 46.2687 52 44.2V33.8C52 33.1104 51.7261 32.4491 51.2385 31.9615C50.7509 31.4739 50.0896 31.2 49.4 31.2ZM24.154 35.646C24.4013 35.8827 24.6928 36.0683 25.012 36.192C25.3232 36.3296 25.6597 36.4006 26 36.4006C26.3403 36.4006 26.6768 36.3296 26.988 36.192C27.3072 36.0683 27.5987 35.8827 27.846 35.646L38.246 25.246C38.7356 24.7564 39.0106 24.0924 39.0106 23.4C39.0106 22.7076 38.7356 22.0436 38.246 21.554C37.7564 21.0644 37.0924 20.7894 36.4 20.7894C35.7076 20.7894 35.0436 21.0644 34.554 21.554L28.6 27.534V2.6C28.6 1.91044 28.3261 1.24912 27.8385 0.761522C27.3509 0.273928 26.6896 0 26 0C25.3104 0 24.6491 0.273928 24.1615 0.761522C23.6739 1.24912 23.4 1.91044 23.4 2.6V27.534L17.446 21.554C16.9564 21.0644 16.2924 20.7894 15.6 20.7894C14.9076 20.7894 14.2436 21.0644 13.754 21.554C13.2644 22.0436 12.9894 22.7076 12.9894 23.4C12.9894 24.0924 13.2644 24.7564 13.754 25.246L24.154 35.646Z"
                fill="#212F54"
              />
            </svg>
                {/* <i className="fa fa-download " style={{ fontSize: "40px" }}></i> */}
              </button>
            </div>
          </div>
        </div>
        <h3 className="text-center my-4">Map</h3>
        <div className="d-flex justify-content-center my-4">
          <Link className="btn btn-primary" to={`/viewlocs/${id}/detailed-map`}>
            View Detailed Map
          </Link>
        </div>
        <div className="position-relative mb-2">
          <LOCsMap
            LOCs={selectedLocs}
            info={mapData.location}
            loading={mapDataLoading}
            isMapLoaded={isLoaded}
          />
          <LOCsPanel
            selectedLocTypes={selectedLocTypes}
            hidePanel={hidePanel}
            setHidePanel={setHidePanel}
            handleSelect={handleSelect}
          />
        </div>
      </div>
    </Fragment>
  );
};
export default ViewLocs;
