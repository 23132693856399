export const DeleteIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path d="M3 6H5H21" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M10 11V17" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14 11V17" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const EditIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none" {...props}>
    <path
      d="M9.16675 3.83301H3.33341C2.89139 3.83301 2.46746 4.0086 2.1549 4.32116C1.84234 4.63372 1.66675 5.05765 1.66675 5.49967V17.1663C1.66675 17.6084 1.84234 18.0323 2.1549 18.3449C2.46746 18.6574 2.89139 18.833 3.33341 18.833H15.0001C15.4421 18.833 15.866 18.6574 16.1786 18.3449C16.4912 18.0323 16.6667 17.6084 16.6667 17.1663V11.333"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.4167 2.5832C15.7483 2.25168 16.1979 2.06543 16.6667 2.06543C17.1356 2.06543 17.5852 2.25168 17.9167 2.5832C18.2483 2.91472 18.4345 3.36436 18.4345 3.8332C18.4345 4.30204 18.2483 4.75168 17.9167 5.0832L10.0001 12.9999L6.66675 13.8332L7.50008 10.4999L15.4167 2.5832Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
