import * as Yup from "yup";

const organizationSchema = (isEdit) => {
  const obj = {
    name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
  };

  if (!isEdit) {
    obj.fullName = Yup.string()
      .min(2, "Too Short!")
      .max(22, "Too Long!")
      .required("Required");

    obj.email = Yup.string().email("Invalid email").required("Required");
  }

  return Yup.object().shape(obj);
};

export default organizationSchema;
