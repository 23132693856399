import React, { Fragment } from "react";
import MailForResetPassword from "../../components/forgetPassword/MailForResetPassword";


const MailForResetPasswordPage = () => {
  return (
    <Fragment>
       <MailForResetPassword />
    </Fragment>
    );
}
export default MailForResetPasswordPage;