import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { notAuthError, url } from "../../constants";

const useUsers = (locationId, addLocation) => {
  const { token } = useSelector((state) => state.login);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (locationId) {
      fetch(
        `${url}/api/locations/` + locationId + `/${addLocation || "all-users"}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            web: true,
          },
        }
      )
        .then((res) => res.json())
        .then((resData) => {
          if (resData.error) {
            if (resData.error === notAuthError) {
              toast("you session expired, please login again.");
              setLoading(false);
            } else {
              toast.error(resData.error);
              setLoading(false);
            }
          } else {
            setUsers(resData.users);
            setLoading(false);
          }
        });
    }
  }, [locationId, token]);

  return { users, loading };
};

export default useUsers;
