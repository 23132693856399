const FormikSelect = ({ formik, name, placeholder, children }) => {
  return (
    <div>
      <select
        id={name}
        className={
          formik.errors[name] && formik.touched[name]
            ? `form-control mt-3 p-2 bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:border-red-500`
            : `form-control mt-3 p-2`
        }
        name={name}
        onChange={formik.handleChange}
        value={formik.values[name]}
      >
        {children}
      </select>
      <p
        className={
          formik.errors[name] && formik.touched[name] && `text-sm text-red-500`
        }
      >
        {formik.errors[name] && formik.touched[name] ? (
          <div className="my-1 text-sm text-red-700">{formik.errors[name]}</div>
        ) : null}
      </p>
    </div>
  );
};

export default FormikSelect;
