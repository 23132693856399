import * as Yup from "yup";

const organizationSchema = (isEdit) => {
  const obj = {
    name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    org_id: Yup.string().required("Required"),
  };

 
  return Yup.object().shape(obj);
};

export default organizationSchema;
