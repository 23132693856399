import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  onChangeLocsInputs,
  onEditingSingleLoc,
  onFetchingSpecificLoc,
} from "../../store/Locs/LocsReducer";
import EditLOCMap from "../Map/EditLOCMap";

import { PropagateLoader } from "react-spinners";
import { css } from "@emotion/react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { notAuthError, url } from "../../constants";
import { notUser } from "../../util/roles";
import NotAuthPage from "../../pages/NotAuth/NotAuthPage";

const styleLinkBack = {
  textDecoration: "none",
  color: "#717993",
  fontSize: "22px",
};

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const UpdateSingleLocInfo = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [disableBtn, setDisableBtn] = useState(false);
  const { token, role } = useSelector((state) => state.login);

  const { selectedIdentifier } = useSelector((state) => state.globalIdentifier);
  const { selectedEditProject } = useSelector((state) => state.projects);
  const { selectedEditLocation } = useSelector((state) => state.locations);

  const [globalIdentifier, setGlobalIdenetifier] = useState(null);
  const [project, setProject] = useState(null);
  const [location, setLocation] = useState(null);
  const [gid, setGid] = useState("");
  const [radius, setRadius] = useState(10);
  const [isSoftLock,setIsSoftLock] = useState(false)
  const [loading,setLoading] = useState(true)

  const { specificLoc, singleLocForm, loadEdit } = useSelector(
    (state) => state.locs
  );

  useEffect(() => {
    fetch(`${url}/api/LOCs/${id}`, {
      headers: { Authorization: `Bearer ${token}`, web: true },
    })
      .then((res) => res.json())
      .then((resData) => {
        if (resData.error) {
          if (resData.error === notAuthError) {
            toast("you session expired, please login again.");
          } else {
            toast.error(resData.error);
            navigate(-1);
          }
        } else {
          setGlobalIdenetifier(resData.globalIdentifier);
          setProject(resData.project);
          setLocation(resData.location);
          setGid(resData.globalIdentifier.gid);
        }
      });
  }, []);

  useEffect(() => {
    dispatch(onFetchingSpecificLoc(id, token));
  }, [dispatch, id, token]);

  useEffect(() => {
    if (specificLoc && specificLoc.loc_id) {
      dispatch(
        onChangeLocsInputs(specificLoc.unique_asset_id, "unique_asset_id")
      );
      dispatch(
        onChangeLocsInputs(specificLoc.actual_asset_name, "actual_asset_name")
      );
      dispatch(onChangeLocsInputs(specificLoc.field_1, "filed1"));
      dispatch(onChangeLocsInputs(specificLoc.field_2, "filed2"));
      dispatch(onChangeLocsInputs(specificLoc.field_3, "filed3"));
      dispatch(
        onChangeLocsInputs(specificLoc.origin_latitude, "origin_latitude")
      );
      dispatch(
        onChangeLocsInputs(specificLoc.origin_longitude, "origin_longitude")
      );
      dispatch(
        onChangeLocsInputs(specificLoc.origin_radius, "origin_radius")
      );
      dispatch(onChangeLocsInputs(specificLoc.notes, "notes"));

      dispatch(onChangeLocsInputs(specificLoc.cable_status, "origin_status"));

      setIsSoftLock(specificLoc.soft_lock)
      setLoading(false)
    }
  }, [specificLoc]);

  if(loading) return (
    <div style={{ textAlign: "center" }}>
      <div className="spinner-border" role="status">
        {/* <span className="sr-only">Loading...</span> */}
      </div>
    </div>
  )

  if(isSoftLock) return <NotAuthPage />

  return (
    <Fragment>
      <ToastContainer />
      <div className="container">
        {globalIdentifier?.name && project?.name && location?.name ? (
          <Fragment>
          <Link
            to={"/globalidenetifiers"}
            style={styleLinkBack}
          >
            Global Identifiers
          </Link>
          <span className="mx-2" style={{ color: "#28345C" }}>
            <i className="fas fa-chevron-right"></i>
            <i className="fas fa-chevron-right"></i>
          </span>
            <Link
              to={"/globalidenetifiers/projects/" + globalIdentifier.gid}
              style={styleLinkBack}
            >
              {globalIdentifier.name}
            </Link>
            <span className="mx-2" style={{ color: "#28345C" }}>
              <i className="fas fa-chevron-right"></i>
              <i className="fas fa-chevron-right"></i>
            </span>
            <Link to={"/locations/" + project.id} style={styleLinkBack}>
              {project.name}
            </Link>
            <span className="mx-2" style={{ color: "#28345C" }}>
              <i className="fas fa-chevron-right"></i>
              <i className="fas fa-chevron-right"></i>
            </span>
            <Link to={"/viewlocs/" + location.id} style={styleLinkBack}>
              {location.name}
            </Link>
          </Fragment>
        ) : null}

        {/* {selectedEditProject.name && selectedIdentifier.name ? (
          <Fragment>
            <Link
              to={"/globalidenetifiers/projects/" + selectedEditProject.gid}
              style={styleLinkBack}
            >
              {selectedIdentifier.name}
            </Link>
            <span className="mx-2" style={{ color: "#28345C" }}>
              <i className="fas fa-chevron-right"></i>
              <i className="fas fa-chevron-right"></i>
            </span>
            <Link
              to={"/locations/" + selectedEditProject.id}
              style={styleLinkBack}
            >
              {selectedEditProject.name}
            </Link>
            <span className="mx-2" style={{ color: "#28345C" }}>
              <i className="fas fa-chevron-right"></i>
              <i className="fas fa-chevron-right"></i>
            </span>
            <Link
              to={"/viewlocs/" + selectedEditLocation.id}
              style={styleLinkBack}
            >
              {selectedEditLocation.name}
            </Link>
          </Fragment>
        ) : null} */}

        {/* <Link to={"/gid"} style={styleLinkBack}>
          GID 1
        </Link>
        <span className="mx-2" style={{ color: "#28345C" }}>
          <i className="fas fa-chevron-right"></i>
          <i className="fas fa-chevron-right"></i>
        </span>
        <Link to={"/gid"} style={styleLinkBack}>
          Project 1
        </Link>
        <span className="mx-2" style={{ color: "#28345C" }}>
          <i className="fas fa-chevron-right"></i>
          <i className="fas fa-chevron-right"></i>
        </span>
        <Link to={"/gid"} style={styleLinkBack}>
          Location 1
        </Link> */}
        <div className="row">
          <div className="col-12 m-auto">
            <h3 className="text-center mt-3">UPDATE SINGLE LOC INFO</h3>
            <form
              className="form-horizontal row mt-3"
              role="form"
              onSubmit={(e) => {
                e.preventDefault();
                dispatch(
                  onEditingSingleLoc(
                    e,
                    role,
                    token,
                    specificLoc.loc_id,
                    singleLocForm.unique_asset_id.value,
                    singleLocForm.actual_asset_name.value,
                    singleLocForm.filed1.value,
                    singleLocForm.filed2.value,
                    singleLocForm.filed3.value,
                    singleLocForm.origin_latitude.value,
                    singleLocForm.origin_longitude.value,
                    singleLocForm.origin_radius.value,
                    singleLocForm.notes.value,
                    specificLoc.soft_lock,
                    specificLoc.hard_lock,
                    id,
                    singleLocForm.origin_status.value,
                    gid,
                    navigate
                  )
                );
              }}
            >
              {notUser.includes(role) && (
                <>
                  <div className="mb-3 m-auto col-md-6">
                    <label
                      htmlFor="RouteID"
                      className="form-label"
                      style={{ color: "#0987B1" }}
                    >
                      Unique Asset ID
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="RouteID"
                      defaultValue="r1"
                      id="RouteID"
                      value={singleLocForm.unique_asset_id.value}
                      onChange={(e) =>
                        dispatch(
                          onChangeLocsInputs(e.target.value, "unique_asset_id")
                        )
                      }
                      style={
                        singleLocForm.unique_asset_id.valid
                          ? {}
                          : { border: "1px solid red" }
                      }
                    />
                    {singleLocForm.unique_asset_id.valid ? null : (
                      <div style={{ color: "red", fontSize: 14 }}>
                        {singleLocForm.unique_asset_id.validationError}
                      </div>
                    )}
                  </div>
                  <div className="mb-3 m-auto col-md-6">
                    <label
                      htmlFor="Origin"
                      className="form-label"
                      style={{ color: "#0987B1" }}
                    >
                      Actual Asset Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="Actual Asset Name"
                      defaultValue="CO1"
                      id="Actual Asset Name"
                      value={singleLocForm.actual_asset_name.value}
                      onChange={(e) =>
                        dispatch(
                          onChangeLocsInputs(
                            e.target.value,
                            "actual_asset_name"
                          )
                        )
                      }
                      style={
                        singleLocForm.actual_asset_name.valid
                          ? {}
                          : { border: "1px solid red" }
                      }
                    />
                    {singleLocForm.actual_asset_name.valid ? null : (
                      <div style={{ color: "red", fontSize: 14 }}>
                        {singleLocForm.actual_asset_name.validationError}
                      </div>
                    )}
                  </div>
                  <div className="mb-3 m-auto col-md-6">
                    <label
                      htmlFor="Field1"
                      className="form-label"
                      style={{ color: "#0987B1" }}
                    >
                      Field 1
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="Field1"
                      defaultValue="OF11"
                      id="Field1"
                      value={singleLocForm.filed1.value}
                      onChange={(e) =>
                        dispatch(onChangeLocsInputs(e.target.value, "filed1"))
                      }
                      style={
                        singleLocForm.filed1.valid
                          ? {}
                          : { border: "1px solid red" }
                      }
                    />
                    {singleLocForm.filed1.valid ? null : (
                      <div style={{ color: "red", fontSize: 14 }}>
                        {singleLocForm.filed1.validationError}
                      </div>
                    )}
                  </div>
                  <div className="mb-3 m-auto col-md-6">
                    <label
                      htmlFor="Field2"
                      className="form-label"
                      style={{ color: "#0987B1" }}
                    >
                      Field 2
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="Field2"
                      defaultValue="OF12"
                      id="Field2"
                      value={singleLocForm.filed2.value}
                      onChange={(e) =>
                        dispatch(onChangeLocsInputs(e.target.value, "filed2"))
                      }
                      style={
                        singleLocForm.filed2.valid
                          ? {}
                          : { border: "1px solid red" }
                      }
                    />
                    {singleLocForm.filed2.valid ? null : (
                      <div style={{ color: "red", fontSize: 14 }}>
                        {singleLocForm.filed2.validationError}
                      </div>
                    )}
                  </div>
                  <div className="mb-3 m-auto col-md-6">
                    <label
                      htmlFor="Field3"
                      className="form-label"
                      style={{ color: "#0987B1" }}
                    >
                      Field 3
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="Field3"
                      defaultValue="OF12"
                      id="Field3"
                      value={singleLocForm.filed3.value}
                      onChange={(e) =>
                        dispatch(onChangeLocsInputs(e.target.value, "filed3"))
                      }
                      style={
                        singleLocForm.filed3.valid
                          ? {}
                          : { border: "1px solid red" }
                      }
                    />
                    {singleLocForm.filed3.valid ? null : (
                      <div style={{ color: "red", fontSize: 14 }}>
                        {singleLocForm.filed3.validationError}
                      </div>
                    )}
                  </div>

                  <div className="my-3 m-auto col-md-6">
                    <label
                      htmlFor="Field3"
                      className="form-label"
                      style={{ color: "#0987B1" }}
                    >
                      Origin Status
                    </label>
                    <select
                      id="select"
                      className="form-select p-2"
                      onChange={(e) =>
                        dispatch(
                          onChangeLocsInputs(e.target.value, "origin_status")
                        )
                      }
                      value={singleLocForm.origin_status.value}
                      style={
                        singleLocForm.origin_status.valid
                          ? {}
                          : { border: "1px solid red" }
                      }
                    >
                      <option value="unassigned">Unassigned</option>
                      <option value="assigned">Assigned</option>
                      {/* <option value="user">USER</option> */}
                    </select>
                    {singleLocForm.origin_status.valid ? null : (
                      <div style={{ color: "red", fontSize: 14 }}>
                        {singleLocForm.origin_status.validationError}
                      </div>
                    )}
                  </div>

                  <div className="mb-3 m-auto col-8 col-md-8">
                    <label
                      htmlFor="Field3"
                      className="form-label"
                      style={{ color: "#0987B1" }}
                    >
                      Notes
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="notes"
                      placeholder="Notes"
                      id="notes"
                      value={singleLocForm.notes.value}
                      onChange={(e) =>
                        dispatch(onChangeLocsInputs(e.target.value, "notes"))
                      }
                    />
                  </div>

                  <h5 className="text-center mt-4 mb-3">Location</h5>
                  <EditLOCMap
                    radius={radius}
                    locationLat={singleLocForm.origin_latitude.value}
                    locationLong={singleLocForm.origin_longitude.value}
                    onChange={onChangeLocsInputs}
                    origin_coordinates={true}
                  />

                  <div className="row my-3">
                    <div className="col">
                      <input
                        type="text"
                        className="form-control"
                        name="Latitude"
                        placeholder="Latitude"
                        disabled={true}
                        onChange={(e) => {
                          dispatch(
                            onChangeLocsInputs(
                              Number(e.target.value),
                              "origin_latitude"
                            )
                          );
                        }}
                        value={singleLocForm.origin_latitude.value}
                        style={
                          singleLocForm.origin_latitude.valid
                            ? {}
                            : { border: "1px solid red" }
                        }
                      />
                      {true ? null : (
                        <div style={{ color: "red", fontSize: 14 }}>
                          {singleLocForm.origin_latitude.validationError}
                        </div>
                      )}
                    </div>
                    <div className="col">
                      <input
                        type="text"
                        className="form-control"
                        name="Longitude"
                        placeholder="Longitude"
                        disabled={true}
                        onChange={(e) =>
                          dispatch(
                            onChangeLocsInputs(
                              Number(e.target.value),
                              "origin_longitude"
                            )
                          )
                        }
                        value={singleLocForm.origin_longitude.value}
                        style={
                          singleLocForm.origin_longitude.valid
                            ? {}
                            : { border: "1px solid red" }
                        }
                      />
                      {true ? null : (
                        <div style={{ color: "red", fontSize: 14 }}>
                          {singleLocForm.origin_longitude.validationError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "90%",
                      display: "flex",
                      // justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="number"
                      step={1}
                      min={0}
                      className="form-control"
                      name="Radius"
                      placeholder="Radius"
                      id="Radius"
                      onChange={(e) => {
                        dispatch(
                          onChangeLocsInputs(e.target.value, "origin_radius")
                        );
                        setRadius(e.target.value);
                      }}
                      value={
                        singleLocForm.origin_radius.value
                          ? singleLocForm.origin_radius.value
                          : 1
                      }
                      // style={
                      //   singleLocForm.radius.valid
                      //     ? {}
                      //     : { border: "1px solid red" }
                      // }
                    />
                    <span style={{ marginLeft: 5 }}>Meter</span>
                  </div>
                </>
              )}

              <div className="d-flex justify-content-center my-4">
                <button
                  disabled={
                    disableBtn
                      ? disableBtn
                      : !(
                          singleLocForm.unique_asset_id.valid &&
                          singleLocForm.actual_asset_name.valid &&
                          singleLocForm.filed1.valid &&
                          singleLocForm.filed2.valid &&
                          singleLocForm.filed3.valid
                        )
                  }
                  type="submit"
                  className="btn btn-primary w-25"
                  style={{ width: "130px", height: "40px" }}
                  onClick={() => {
                    setTimeout(() => {
                      setDisableBtn(true);
                    }, 1);
                    setTimeout(() => {
                      setDisableBtn(false);
                    }, 2000);
                  }}
                >
                  {loadEdit ? (
                    <PropagateLoader color={"#fff"} css={override} size={10} />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UpdateSingleLocInfo;
