import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./assets/css/bootstrap.min.css";
import "./App.css";
import { toast } from "react-toastify";

import UsersPage from "./pages/users/UsersPage";
import LoginPage from "./pages/login/LoginPage";
import SignupPage from "./pages/signup/SignupPage";
import HomePage from "./pages/home/HomePage";
import GidPage from "./pages/gid/GidPage";
import UserLogsPage from "./pages/users/UsersLogsPage";
import GidLogsPage from "./pages/gid/GidLogsPage";
import ProjectLogsPage from "./pages/project/ProjectLogsPage";
import LocationLogsPage from "./pages/project/LocationLogsPage";

import GlobalIdenetifiersPage from "./pages/global idenetifiers/GlobalIdenetifiersPage";
import GlobalSearchPage from "./pages/global idenetifiers/GlobalSearchPage";
import AddNewGlobalIdenetifiersPage from "./pages/global idenetifiers/AddNewGlobalIdenetifiersPage";
import EditGlobalIdenetifiersPage from "./pages/global idenetifiers/EditGlobalIdenetifiersPage";
import ProjectPage from "./pages/project/ProjectPage";
import AddNewProjectPage from "./pages/project/AddNewProjectPage";
import EditProjectPage from "./pages/project/EditProjectPage";
import AddNewLocationPage from "./pages/project/AddNewLocationPage";
import EditLocationPage from "./pages/project/EditLocationPage";
import ProjectsPage from "./pages/project/ProjectsPage";
import ProjectConsumptionReportPage from "./pages/project/ProjectConsumptionReportPage";
import LocationConsumptionReportPage from "./pages/project/LocationConsumptionReportPage";
import ViewLocsPage from "./pages/locs/ViewLocsPage";
import AssignedLocsPage from "./pages/locs/AssignedLocsPage";
import UnAssignedLocsPage from "./pages/locs/UnAssignedLocsPage";
import CreateDualLocInfoPage from "./pages/locs/CreateDualLocInfoPage";
import CreateSingleLocInfoPage from "./pages/locs/CreateSingleLocInfoPage";
import UpdateDualLocInfoPage from "./pages/locs/UpdateDualLocInfoPage";
import UpdateSingleLocInfoPage from "./pages/locs/UpdateSingleLocInfoPage";
import NotFound404Page from "./pages/NotFound404/NotFound404Page";
import ForgetPasswordPage from "./pages/forgetPassword/ForgetPasswordPage";
import MailForResetPasswordPage from "./pages/forgetPassword/MailForResetPasswordPage";
import UpgradeTags from "./pages/upgradeTags";
import ConsumptionReportPage from "./pages/consumptionReport/ConsumptionReport";

import { authCheckState } from "./store/Login/Login";

import Organizations from "./pages/organizations/Organizations";
import Organization from "./pages/organizations/Organization";
import useAutoLogout from "./hooks/useAutoLogout";
import { useLogout } from "./contexts/LogoutContext";
import ProjectDetailedMapPage from "./pages/project/ProjectDetailedMapPage";
import LocationDetailedMapPage from "./pages/project/LocationDetailedMapPage";
import ThirdParties from "./pages/thirdParty/ThirdParties";
import Hero from "./pages/hero/Hero";
import OTPSigninPage from "./pages/OTPSignin/OTPSigninPage";
import RecycleBinPage from "./pages/project/RecycleBinPage";

function App() {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.login.token);
  const { logout, setLogout } = useLogout();

  // its working dont remove the timer.
  // const timer = useAutoLogout(60 * 15);

  useEffect(() => {
    document.title = "Label on Cable";
  }, []);

  useEffect(() => {
    dispatch(authCheckState());
  }, [dispatch, token]);

  useEffect(() => {
    toast.onChange((payload) => {
      if (
        payload.content === "you session expired, please login again." ||
        payload.content === "Unable to authenticate!"
      ) {
        setLogout(true);
      }
    });
  }, [toast]);

  if (!token) {
    return (
      <Routes>
        <Route path="*" element={<Hero />} />
        <Route path="/" element={<Hero/>} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/resetpassword" element={<ForgetPasswordPage />} />
        <Route path="/forgetpassword" element={<MailForResetPasswordPage />} />
        <Route path="/2fa" element={<OTPSigninPage />} />
      </Routes>
    );
  }

  return (
    <div>
      <Routes>
        <Route path="*" element={<NotFound404Page />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/users" element={<UsersPage />} />
        <Route path="/userslogs/:id" element={<UserLogsPage />} />
        <Route path="/gid" element={<GidPage />} />
        <Route path="/gidlogs/:id" element={<GidLogsPage />} />


        <Route
          path="/globalsearch"
          element={<GlobalSearchPage />}
        />
        <Route
          path="/globalidenetifiers"
          element={<GlobalIdenetifiersPage />}
        />
        <Route
          path="/addglobalidenetifiers"
          element={<AddNewGlobalIdenetifiersPage />}
        />
        <Route
          path="/editglobalidenetifiers/:id"
          element={<EditGlobalIdenetifiersPage />}
        />
        <Route
          path="/globalidenetifiers/projects/:id"
          element={<ProjectsPage />}
        />

        <Route path="/projectlogs/:id" element={<ProjectLogsPage />} />
        <Route path="/locationlogs/:id" element={<LocationLogsPage />} />

        <Route path="/locations/:id" element={<ProjectPage />} />
        <Route
          path="/locations/:id/detailed-map"
          element={<ProjectDetailedMapPage />}
        />
        <Route
          path="/locations/:id/consumption-report"
          element={<ProjectConsumptionReportPage />}
        />
        <Route path="/addnewproject/:id" element={<AddNewProjectPage />} />
        <Route path="/editproject/:id" element={<EditProjectPage />} />
        <Route path="/addnewlocation/:id" element={<AddNewLocationPage />} />
        <Route path="/editlocation/:id" element={<EditLocationPage />} />

        <Route
          path="/viewlocs/:id/consumption-report"
          element={<LocationConsumptionReportPage />}
        />
        <Route path="/viewlocs/:id" element={<ViewLocsPage />} />
        <Route
          path="/viewlocs/:id/detailed-map"
          element={<LocationDetailedMapPage />}
        />
        <Route
          path="/viewlocs/:id/recycle-bin"
          element={<RecycleBinPage />}
        />

        <Route path="/assignedlocs/:id" element={<AssignedLocsPage />} />
        <Route path="/unassignedlocs/:id" element={<UnAssignedLocsPage />} />
        <Route
          path="/CreateDualLocInfo/:id"
          element={<CreateDualLocInfoPage />}
        />
        <Route
          path="/CreateSingleLocInfo/:id"
          element={<CreateSingleLocInfoPage />}
        />
        <Route
          path="/UpdateDualLocInfo/:id"
          element={<UpdateDualLocInfoPage />}
        />
        <Route
          path="/UpdateSingleLocInfo/:id"
          element={<UpdateSingleLocInfoPage />}
        />
        <Route path="/UpgradeTags/:id" element={<UpgradeTags />} />

        <Route path="/organizations" element={<Organizations />} />

        <Route path="/organization" element={<Organization />} />
        <Route path="/consumption-report" element={<ConsumptionReportPage />} />
        <Route
          path="/consumption-report/:id"
          element={<ConsumptionReportPage />}
        />

        <Route path="/third-party" element={<ThirdParties />} />
      </Routes>
    </div>
  );
}

export default App;
