import oneBlack from "../assets/images/fieldIcons/black/1.png";
import twoBlack from "../assets/images/fieldIcons/black/2.png";
import threeBlack from "../assets/images/fieldIcons/black/3.png";
import fourBlack from "../assets/images/fieldIcons/black/4.png";
import fiveBlack from "../assets/images/fieldIcons/black/5.png";
import sixBlack from "../assets/images/fieldIcons/black/6.png";
import sevenBlack from "../assets/images/fieldIcons/black/7.png";
import eightBlack from "../assets/images/fieldIcons/black/8.png";
import nineBlack from "../assets/images/fieldIcons/black/9.png";
import tenBlack from "../assets/images/fieldIcons/black/10.png";

import oneRed from "../assets/images/fieldIcons/red/1.png";
import twoRed from "../assets/images/fieldIcons/red/2.png";
import threeRed from "../assets/images/fieldIcons/red/3.png";
import fourRed from "../assets/images/fieldIcons/red/4.png";
import fiveRed from "../assets/images/fieldIcons/red/5.png";
import sixRed from "../assets/images/fieldIcons/red/6.png";
import sevenRed from "../assets/images/fieldIcons/red/7.png";
import eightRed from "../assets/images/fieldIcons/red/8.png";
import nineRed from "../assets/images/fieldIcons/red/9.png";
import tenRed from "../assets/images/fieldIcons/red/10.png";

import oneGreen from "../assets/images/fieldIcons/green/1.png";
import twoGreen from "../assets/images/fieldIcons/green/2.png";
import threeGreen from "../assets/images/fieldIcons/green/3.png";
import fourGreen from "../assets/images/fieldIcons/green/4.png";
import fiveGreen from "../assets/images/fieldIcons/green/5.png";
import sixGreen from "../assets/images/fieldIcons/green/6.png";
import sevenGreen from "../assets/images/fieldIcons/green/7.png";
import eightGreen from "../assets/images/fieldIcons/green/8.png";
import nineGreen from "../assets/images/fieldIcons/green/9.png";
import tenGreen from "../assets/images/fieldIcons/green/10.png";

export const locTypes = [
    'unassigned - single',
    'assigned - single',
    'unassigned - dual - origin',
    'unassigned - dual - destination',
    'assigned - dual - origin',
    'assigned - dual - destination'
]

 export const fieldImagesBlack = [oneBlack, twoBlack, threeBlack, fourBlack, fiveBlack, sixBlack, sevenBlack, eightBlack, nineBlack, tenBlack]
 export const fieldImagesRed = [oneRed, twoRed, threeRed, fourRed, fiveRed, sixRed, sevenRed, eightRed, nineRed, tenRed]
 export const fieldImagesGreen = [oneGreen, twoGreen, threeGreen, fourGreen, fiveGreen, sixGreen, sevenGreen, eightGreen, nineGreen, tenGreen]
