import React, { useEffect, useContext, useState, createContext } from "react";
import { onLogout } from "../store/Login/Login";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export const LogoutContext = createContext({
  logout: false,
  setLogout: () => {},
});

export const LogoutProvider = ({ children }) => {
  const [logout, setLogout] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const contextValue = {
    logout,
    setLogout,
  };

  useEffect(() => {
    if (logout) {
      dispatch(onLogout(navigate));
    }
  }, [logout]);

  return (
    <LogoutContext.Provider value={contextValue}>
      {children}
    </LogoutContext.Provider>
  );
};

export const useLogout = () => useContext(LogoutContext);
