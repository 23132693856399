import { useState, useEffect } from "react";

import useUsers from "../hooks/location/useUsers";
import useAssignedUsers from "../hooks/location/useAssignedUsers";

const useLocationUsersContainer = (locationId) => {
  const { loading: usersLoading, users } = useUsers(locationId);
  const { loading: assignedLoading, assignedUsers: data } =
  useAssignedUsers(locationId);
  const [assignedUsers, setAssignedUsers] = useState([]);

  useEffect(() => {
    if (data) {
      setAssignedUsers(data);
    }
  }, [data]);

  const loading = assignedLoading || usersLoading;

  const onAssignUser = (userId) => {
    if (!assignedUsers.includes(userId)) {
      setAssignedUsers([...assignedUsers, userId]);
    } else {
      setAssignedUsers(assignedUsers.filter((user) => user !== userId));
    }
  };

  return { assignedUsers,setAssignedUsers, onAssignUser, users, loading };
};

export default useLocationUsersContainer;
