import React, { Fragment } from 'react'
import NotFound404 from '../../components/NotFound404/NotFound404'

export default function NotFound404Page() {
  return (
    <Fragment>
         <NotFound404 />
    </Fragment>
  )
}
