import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { notAuthError, url } from "../constants";
import { toast } from "react-toastify";
import roles from "../util/roles";

const useOrganizations = () => {
  const [loading, setLoading] = useState(true);
  const [organizations, setOrganizations] = useState([]);
  const { token, role } = useSelector((state) => state.login);

  useEffect(() => {
    if (
      role === roles.saas_admin ||
      role === roles.super_admin ||
      role === roles.admin
    ) {
      fetch(`${url}/api/organizations`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          web: true,
        },
      })
        .then((res) => res.json())
        .then((resData) => {
          console.log("result", resData);
          if (resData.error) {
            if (resData.error === notAuthError) {
              toast("you session expired, please login again.");
              setLoading(false);
            } else {
              toast.error(resData.error);
              setLoading(false);
            }
          } else {
            setOrganizations(resData.organizations);
            setLoading(false);
          }
        });
    } else {
      setLoading(false);
    }
  }, [role]);

  return { organizations, loading };
};

export default useOrganizations;
