import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  onDeletingLoc,
  onFetchingLocs,
  onSearchingLoc,
  onSortingLocs,
  onDeletingSelectedLoc,
  toggleLock,
} from "../../store/Locs/LocsReducer";
import { notAuthError, url } from "../../constants";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";

import { ExcelRenderer } from "react-excel-renderer";
import Modal from "react-modal";
import styles from "./../project/Project.module.css";
import { notAdminOrUser, notUser } from "../../util/roles";
import SingleLocsTable from "./SingleLocsTable";
import DualLocsTable from "./DualLocsTable";

const styleLinkBack = {
  textDecoration: "none",
  color: "#717993",
  fontSize: "22px",
};
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const AssignedLocs = () => {
  const [DeleteSingleIsOpen, setDeleteSingleIsOpen] = useState(false);
  const [singleLocID, setSingleLocID] = useState("");
  const [DeleteDualIsOpen, setDeleteDualIsOpen] = useState(false);
  const [DeleteMultiIsOpen, setDeleteMultiIsOpen] = useState(false);
  const [DeleteMultiDualIsOpen, setDeleteMultiDualIsOpen] = useState(false);

  const [dualLocID, setDualLocID] = useState("");
  const { id } = useParams();
  const dispatch = useDispatch();
  const { token, role } = useSelector((state) => state.login);

  const [globalIdentifier, setGlobalIdenetifier] = useState(null);
  const [gid, setGid] = useState("");
  const [project, setProject] = useState(null);
  const [location, setLocation] = useState(null);
  const [selectedSingleLocs, setSelectedSingleLocs] = useState([]);
  const [selectedDualLocs, setSelectedDualLocs] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [LOCSearchType, setLOCSearchType] = useState("unique_asset_id");

  const [softLockOpen, setSoftLockOpen] = useState(false);
  const [selectedLoc, setSelectedLoc] = useState(null);

  const onSelectLoc = (id, type) => {
    if (type === "single") {
      if (selectedSingleLocs.includes(id)) {
        setSelectedSingleLocs(selectedSingleLocs.filter((loc) => loc !== id));
      } else {
        setSelectedSingleLocs([...selectedSingleLocs, id]);
      }
    } else {
      if (selectedDualLocs.includes(id)) {
        setSelectedDualLocs(selectedDualLocs.filter((loc) => loc !== id));
      } else {
        setSelectedDualLocs([...selectedDualLocs, id]);
      }
    }
  };

  const onSelectAllSingleLocs = (arr) => {
    if (selectedSingleLocs.toString() === arr.toString()) {
      setSelectedSingleLocs([]);
    } else {
      setSelectedSingleLocs(arr);
    }
  };

  const onSelectAllDualLocs = (arr) => {
    if (selectedDualLocs.toString() === arr.toString()) {
      setSelectedDualLocs([]);
    } else {
      setSelectedDualLocs(arr);
    }
  };

  const handleOpenLockModal = (loc) => {
    setSoftLockOpen(true);
    setSelectedLoc(loc);
  };

  const handleCloseModal = () => {
    setSoftLockOpen(false);
    setSelectedLoc(null);
  };

  const handleToggleSoftLock = () => {
    dispatch(
      toggleLock(
        selectedLoc.loc_id,
        selectedLoc.LOC_type,
        token,
        handleCloseModal
      )
    );
  };

  const {
    singleLocs,
    dualLocs,
    loadingLocs,
    renderedItem,
    searchSingleLocs,
    searchDualLocs,
    loadSearch,
  } = useSelector((state) => state.locs);

  useEffect(() => {
    dispatch(onFetchingLocs(id, token, "assigned"));
    dispatch(onSearchingLoc(singleLocs, dualLocs, ""));
  }, []);

  const [state, setState] = useState({
    cols: null,
    rows: null,
  });

  const [file, setFile] = useState({});
  const fileHandler = (event) => {
    let fileObj = event.target.files[0];
    setFile(fileObj);
    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        setState({
          cols: resp.cols,
          rows: resp.rows,
        });
      }
    });
  };

  const onUploadFile = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("LocFile", file);
    fetch(`${url}/api/LOCs/upload`, {
      method: "POST",
      headers: { Authorization: `Bearer ${token}`, web: true },
      body: formData,
    }).then((res) => res.json());
  };

  useEffect(() => {
    fetch(`${url}/api/locations/${id}`, {
      headers: { Authorization: `Bearer ${token}`, web: true },
    })
      .then((res) => res.json())
      .then((resData) => {
        if (resData.error) {
          if (resData.error === notAuthError) {
            toast("you session expired, please login again.");
          } else {
            toast.error(resData.error);
          }
        } else {
          setGlobalIdenetifier(resData.globalIdentifier);
          setProject(resData.project);
          setLocation(resData.location);
          setGid(resData.globalIdentifier.gid);
        }
      });
  }, []);

  const [sortType, setSortType] = useState("");
  const [locSorted, setLocSorted] = useState("");

  const sortTable = (locType, sort) => {
    let type = "desc";
    setLocSorted(locType);
    if (sort === sortType) {
      setSortType("");
      type = "asc";
    } else {
      setSortType(sort);
    }
    onSortingLocs(dualLocs, singleLocs, type, sort, locType);
  };

  return (
    <Fragment>
      <div className="container">
        <ToastContainer />

        {globalIdentifier?.name && project?.name && location?.name ? (
          <Fragment>
          <Link
            to={"/globalidenetifiers"}
            style={styleLinkBack}
          >
            Global Identifiers
          </Link>
          <span className="mx-2" style={{ color: "#28345C" }}>
            <i className="fas fa-chevron-right"></i>
            <i className="fas fa-chevron-right"></i>
          </span>
            <Link
              to={"/globalidenetifiers/projects/" + globalIdentifier.gid}
              style={styleLinkBack}
            >
              {globalIdentifier.name}
            </Link>
            <span className="mx-2" style={{ color: "#28345C" }}>
              <i className="fas fa-chevron-right"></i>
              <i className="fas fa-chevron-right"></i>
            </span>
            <Link to={"/locations/" + project.id} style={styleLinkBack}>
              {project.name}
            </Link>
            <span className="mx-2" style={{ color: "#28345C" }}>
              <i className="fas fa-chevron-right"></i>
              <i className="fas fa-chevron-right"></i>
            </span>
            <Link to={"/viewlocs/" + location.id} style={styleLinkBack}>
              {location.name}
            </Link>
            <span className="mx-2" style={{ color: "#28345C" }}>
              <i className="fas fa-chevron-right"></i>
              <i className="fas fa-chevron-right"></i>
            </span>
            <Link to={"/assignedlocs/" + id} style={styleLinkBack}>
              Assigned
            </Link>
          </Fragment>
        ) : null}

        {/* {selectedEditProject.name && selectedIdentifier.name ? (
          <Fragment>
            <Link
              to={"/globalidenetifiers/projects/" + selectedEditProject.gid}
              style={styleLinkBack}
            >
              {selectedIdentifier.name}
            </Link>
            <span className="mx-2" style={{ color: "#28345C" }}>
              <i className="fas fa-chevron-right"></i>
              <i className="fas fa-chevron-right"></i>
            </span>
            <Link
              to={"/locations/" + selectedEditProject.id}
              style={styleLinkBack}
            >
              {selectedEditProject.name}
            </Link>
            <span className="mx-2" style={{ color: "#28345C" }}>
              <i className="fas fa-chevron-right"></i>
              <i className="fas fa-chevron-right"></i>
            </span>
            <Link
              to={"/viewlocs/" + selectedEditLocation.id}
              style={styleLinkBack}
            >
              {selectedEditLocation.name}
            </Link>
            <span className="mx-2" style={{ color: "#28345C" }}>
              <i className="fas fa-chevron-right"></i>
              <i className="fas fa-chevron-right"></i>
            </span>
            <Link to={"/assignedlocs/" + id} style={styleLinkBack}>
              ASSIGNED
            </Link>
          </Fragment>
        ) : null} */}

        {/* <Link to={"/gid"} style={styleLinkBack}>
          GID 1
        </Link>
        <span className="mx-2" style={{ color: "#28345C" }}>
          <i className="fas fa-chevron-right"></i>
          <i className="fas fa-chevron-right"></i>
        </span>
        <Link to={"/gid"} style={styleLinkBack}>
          Project 1
        </Link>
        <span className="mx-2" style={{ color: "#28345C" }}>
          <i className="fas fa-chevron-right"></i>
          <i className="fas fa-chevron-right"></i>
        </span>
        <Link to={"/gid"} style={styleLinkBack}>
          Location 1
        </Link> */}
        {/* <span className="mx-2" style={{ color: "#28345C" }}>
          <i className="fas fa-chevron-right"></i>
          <i className="fas fa-chevron-right"></i>
        </span>
        <Link to={"/gid"} style={styleLinkBack}>
          ASSIGNED
        </Link> */}
        <div className="row w-75 m-auto mb-3 ">
          {/* <div className="col d-flex justify-content-end">
            <Link
              to={"/CreateDualLocInfo/" + id}
              type="button"
              className="btn btn-primary btn-sm"
            >
              Create New Dual LOC Info
            </Link>
          </div> */}
          {/* <div className="col d-flex justify-content-center">
            <Link
              to={"/CreateSingleLocInfo/" + id}
              className="btn btn-primary btn-sm"
            >
              Create New Single LOC Info
            </Link>
          </div> */}
          {/* <div className="col d-flex justify-content-right">
            <button
              type="button"
              className="btn btn-success btn-sm"
              data-bs-toggle="modal"
              data-bs-target="#ImportModal"
            >
              Import New LOC Via.Xisx
            </button>
          </div> */}
          {/* Model */}
          <form onSubmit={(e) => onUploadFile(e)}>
            <div
              className="modal fade"
              id="ImportModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
              style={{ borderBlockColor: "#0987B1" }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="row">
                      <div className="col">
                        <h3 className="text-center mt-5 text-primary">
                          IMPORT LOC INFO
                        </h3>
                        <div className="d-flex justify-content-center">
                          {file && file.name}
                        </div>
                        <div className="d-flex justify-content-center">
                          <label className="btn btn-outline-primary btn-sm my-3">
                            <input
                              type="file"
                              style={{ display: "none" }}
                              onChange={(e) => fileHandler(e)}
                            />
                            Choose File
                          </label>
                        </div>
                        <p className="text-center">
                          Only .xlsx files can be uploaded
                        </p>
                        <div className="d-flex justify-content-center mb-5">
                          <button
                            type="submit"
                            className="btn btn-primary btn-sm w-25"
                          >
                            Upload
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          {/* End Model */}
        </div>
        <div className="row">
          <div className="col-12 m-auto">
            <h3 className="my-3">Assigned LOC’s</h3>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                className="w-75 m-auto my-4"
                style={{ position: "relative" }}
              >
                <i
                  className="far fa-search text-dark"
                  style={{ position: "absolute", top: "30%", left: "1%" }}
                ></i>
                <input
                  style={{ paddingLeft: 30 }}
                  type="text"
                  className="form-control"
                  name="search"
                  placeholder="Search by..."
                  value={searchValue}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    setSelectedSingleLocs([]);
                    setSelectedDualLocs([]);
                    dispatch(
                      onSearchingLoc(
                        singleLocs,
                        dualLocs,
                        e.target.value,
                        LOCSearchType
                      )
                    );
                  }}
                />
              </div>
              <div class="inline-block relative w-64 ml-2">
                <select
                  onChange={(e) => {
                    setSearchValue("");
                    setSelectedSingleLocs([]);
                    setSelectedDualLocs([]);
                    dispatch(
                      onSearchingLoc(singleLocs, dualLocs, "", e.target.value)
                    );
                    setLOCSearchType(e.target.value);
                  }}
                  class="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded  leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value="unique_asset_id">
                    Unique Asset ID / Route ID
                  </option>
                  <option value="actual_asset_name">
                    Unique Asset Name / Origin
                  </option>
                  <option value="field_1">FIELD 1</option>
                  <option value="field_2">FIELD 2</option>
                  <option value="field_3">FIELD 3</option>
                  <option value="notes">NOTES</option>
                  <option value="email">LAST UPDATED BY</option>
                  <option value="destination">DESTINATION</option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          {loadingLocs ? (
            <div style={{ textAlign: "center" }}>
              <div className="spinner-border" role="status">
                {/* <span className="sr-only">Loading...</span> */}
              </div>
            </div>
          ) : singleLocs && singleLocs.length > 0 ? (
            <div className="row col-12">
              <div className="d-flex align-items-center mt-4 justify-content-between">
                <h5 className="mr-2 font-bold mb-3">Single LOC’s</h5>
                {selectedSingleLocs.length !== 0 && (
                  <button
                    onClick={() => setDeleteMultiIsOpen(true)}
                    className="mb-2 text-center rounded py-1 focus:outline-none bg-danger border-none text-white"
                  >
                    Delete selected single LOCs
                  </button>
                )}
              </div>

              <div
                className="table-responsive col-12 text-center px-0"
                style={{ maxHeight: 400, overflowY: "auto" }}
              >
                <SingleLocsTable
                  role={role}
                  searchSingleLocs={searchSingleLocs}
                  searchValue={searchValue}
                  singleLocs={singleLocs}
                  selectedSingleLocs={selectedSingleLocs}
                  onSelectLoc={onSelectLoc}
                  onSelectAllSingleLocs={onSelectAllSingleLocs}
                  sortTable={sortTable}
                  sortType={sortType}
                  locSorted={locSorted}
                  renderedItem={renderedItem}
                  notUser={notUser}
                  setSingleLocID={setSingleLocID}
                  setDeleteSingleIsOpen={setDeleteSingleIsOpen}
                  handleOpenLockModal={handleOpenLockModal}
                  notAdminOrUser={notAdminOrUser}
                  loadSearch={loadSearch}
                />
              </div>
            </div>
          ) : (
            <h1 style={{ textAlign: "center", margin: "20px 0" }}>
              No Single LOC’s added yet
            </h1>
          )}

          {loadingLocs ? (
            <div style={{ textAlign: "center" }}>
              <div className="spinner-border" role="status">
                {/* <span className="sr-only">Loading...</span> */}
              </div>
            </div>
          ) : dualLocs && dualLocs.length > 0 ? (
            <div className="row col-12">
              <div className="d-flex align-items-center mt-4 justify-content-between">
                <h5 className="mr-2 font-bold mb-3">Dual LOC’s</h5>
                {selectedDualLocs.length !== 0 && (
                  <button
                    onClick={() => setDeleteMultiDualIsOpen(true)}
                    className="mb-2 text-center rounded py-1 focus:outline-none bg-danger border-none text-white"
                  >
                    Delete selected dual LOCs
                  </button>
                )}
              </div>
              <div
                className="table-responsive col-12 text-center px-0"
                style={{ maxHeight: 400, overflowY: "auto" }}
              >
                <DualLocsTable
                  role={role}
                  searchDualLocs={searchDualLocs}
                  searchValue={searchValue}
                  dualLocs={dualLocs}
                  selectedDualLocs={selectedDualLocs}
                  onSelectLoc={onSelectLoc}
                  onSelectAllDualLocs={onSelectAllDualLocs}
                  sortTable={sortTable}
                  sortType={sortType}
                  locSorted={locSorted}
                  renderedItem={renderedItem}
                  notUser={notUser}
                  setDualLocID={setDualLocID}
                  setDeleteDualIsOpen={setDeleteDualIsOpen}
                  notAdminOrUser={notAdminOrUser}
                  loadSearch={loadSearch}
                  handleOpenLockModal={handleOpenLockModal}
                  softLockOpen={softLockOpen}
                  selectedLoc={selectedLoc}
                  setSoftLockOpen={setSoftLockOpen}
                  setSelectedLoc={setSelectedLoc}
                  handleCloseModal={handleCloseModal}
                  handleToggleSoftLock={handleToggleSoftLock}
                />
              </div>
            </div>
          ) : (
            <h1 style={{ textAlign: "center", margin: "20px 0" }}>
              No dual LOC’s added yet
            </h1>
          )}
        </div>
      </div>

      {/* Delete Modal Single*/}
      <Modal
        isOpen={DeleteSingleIsOpen}
        style={customStyles}
        onRequestClose={() => setDeleteSingleIsOpen(false)}
        contentLabel="Delete Modal"
        className={styles.Modal}
        overlayClassName={styles.Overlay}
        ariaHideApp={false}
      >
        <div className="container modal-body">
          <div className="row">
            <div className="col-10 m-auto">
              <h5 className="text-center my-3">
                Are you sure you want to delete this?
              </h5>

              <div className="d-flex justify-content-center my-3">
                <button
                  type="button"
                  className="btn btn-danger mx-2"
                  onClick={(e) => {
                    dispatch(
                      onDeletingLoc(e, singleLocID, token, "single", gid)
                    );
                    setDeleteSingleIsOpen(false);
                  }}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="btn btn-secondary mx-2"
                  onClick={() => setDeleteSingleIsOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* End Delete Modal Single */}

      {/* Delete Modal Dual*/}
      <Modal
        isOpen={DeleteDualIsOpen}
        style={customStyles}
        onRequestClose={() => setDeleteDualIsOpen(false)}
        contentLabel="Delete Modal"
        className={styles.Modal}
        overlayClassName={styles.Overlay}
        ariaHideApp={false}
      >
        <div className="container modal-body">
          <div className="row">
            <div className="col-10 m-auto">
              <h5 className="text-center my-3">
                Are you sure you want to delete this?
              </h5>

              <div className="d-flex justify-content-center my-3">
                <button
                  type="button"
                  className="btn btn-danger mx-2"
                  onClick={(e) => {
                    dispatch(onDeletingLoc(e, dualLocID, token, "dual", gid));
                    setDeleteDualIsOpen(false);
                  }}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="btn btn-secondary mx-2"
                  onClick={() => setDeleteDualIsOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* End Delete Modal Dual */}

      {/* Delete Multi Modal*/}
      <Modal
        isOpen={DeleteMultiIsOpen}
        style={customStyles}
        onRequestClose={() => setDeleteMultiIsOpen(false)}
        contentLabel="Delete Modal"
        className={styles.Modal}
        overlayClassName={styles.Overlay}
        ariaHideApp={false}
      >
        <div className="container modal-body">
          <div className="row">
            <div className="col-10 m-auto">
              <h5 className="text-center my-3">
                Are you sure you want to delete all selected LOCs?
              </h5>

              <div className="d-flex justify-content-center my-3">
                <button
                  type="button"
                  className="btn btn-danger mx-2"
                  onClick={(e) => {
                    dispatch(
                      onDeletingSelectedLoc(
                        e,
                        selectedSingleLocs,
                        [],
                        dualLocs,
                        singleLocs,
                        token,
                        gid
                      )
                    );
                    setDeleteMultiIsOpen(false);
                    setSelectedSingleLocs([]);
                  }}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="btn btn-secondary mx-2"
                  onClick={() => setDeleteMultiIsOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

        {/* Delete Multi Dual Modal*/}
        <Modal
        isOpen={DeleteMultiDualIsOpen}
        style={customStyles}
        onRequestClose={() => setDeleteMultiDualIsOpen(false)}
        contentLabel="Delete Modal"
        className={styles.Modal}
        overlayClassName={styles.Overlay}
        ariaHideApp={false}
      >
        <div className="container modal-body">
          <div className="row">
            <div className="col-10 m-auto">
              <h5 className="text-center my-3">
                Are you sure you want to delete all selected LOCs?
              </h5>

              <div className="d-flex justify-content-center my-3">
                <button
                  type="button"
                  className="btn btn-danger mx-2"
                  onClick={(e) => {
                    dispatch(
                      onDeletingSelectedLoc(
                        e,
                        [],
                        selectedDualLocs,
                        dualLocs,
                        singleLocs,
                        token,
                        gid
                      )
                    );
                    setDeleteMultiDualIsOpen(false);
                    setSelectedDualLocs([]);
                  }}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="btn btn-secondary mx-2"
                  onClick={() => setDeleteMultiDualIsOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* soft lock  */}
      <Modal
        isOpen={softLockOpen}
        style={customStyles}
        onRequestClose={handleCloseModal}
        contentLabel="Soft Lock Modal"
        className={styles.Modal}
        overlayClassName={styles.Overlay}
        ariaHideApp={false}
      >
        <div className="container modal-body">
          <div className="row">
            <div className="col-10 m-auto">
              <h5 className="text-center my-3">
                Are you sure you want to{" "}
                {selectedLoc?.soft_lock ? "deactivate" : "activate"} soft lock
                for ({selectedLoc?.actual_asset_name})?
              </h5>

              <div className="d-flex justify-content-center my-3">
                <button
                  type="button"
                  className="btn btn-primary mx-2"
                  onClick={handleToggleSoftLock}
                >
                  {selectedLoc?.soft_lock ? "Deactivate" : "Activate"}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary mx-2"
                  onClick={handleCloseModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};
export default AssignedLocs;
