import { useState } from "react";
import { notAuthError, url } from "../../constants";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import NotAuthPage from "../../pages/NotAuth/NotAuthPage";
import fileDownload from "js-file-download";

const ProjectConsumptionReport = ({ id }) => {
  const [startDate, setStartDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [data, setData] = useState({
    assignedDualLOCS: 0,
    assignedSingleLOCS: 0,
    dualLOCs: [],
    singleLOCs: [],
    unassignedDualLOCS: 0,
    unassignedSingleLOCS: 0,
  });
  const { token } = useSelector((state) => state.login);
  if (!id) return <NotAuthPage />;

  const handleGetReport = () => {
    fetch(
      `${url}/api/projects/${id}/consumption-report/${new Date(
        startDate
      ).toISOString()}/${new Date(endDate).toISOString()}`,
      {
        headers: { Authorization: `Bearer ${token}`, web: true },
      }
    )
      .then((res) => res.json())
      .then((resData) => {
        if (resData.error) {
          if (resData.error === notAuthError) {
            toast("you session expired, please login again.");
          } else {
            toast.error(resData.error);
          }
        } else {
          setData(resData);
        }
      });
  };

  const handleDownload = (type) => {
    fetch(
      `${url}/api/projects/${id}/consumption-report/download/${type}/${new Date(
        startDate
      ).toISOString()}/${new Date(endDate).toISOString()}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          web: true,
        },
      }
    )
      .then(async (response) => {
        if (response.ok) {
          return response.blob();
        }
        return Promise.reject(
          response.text().then((text) => {
            if (text) {
              toast.error(JSON.parse(text).error);
            } else {
              toast.error("Something went wrong...");
            }
            throw new Error(text);
          })
        );
      })
      .then((blob) => {
        fileDownload(blob, `loc report.xlsx`);
      });
  };

  return (
    // <>{id}</>
    <>
      <ToastContainer />
      <div className="container">
        <div className="col-12 col-md-6  m-auto">
          <h3
            className="text-center my-4"
            style={{ textTransform: "uppercase" }}
          >
            LOCs Assigned/Unassigned Report
          </h3>
        </div>
        <div className="row align-items-center">
          <div className="col-12 col-lg-5">
            <label>Select start date</label>
            <input
              max={new Date()}
              value={startDate}
              onChange={(e) => {
                setStartDate(e.target.value);
              }}
              type="date"
              className="bg-gray-50 mb-3 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
            />
          </div>
          <div className="col-12 col-lg-5">
            <label>Select end date</label>
            <input
              min={startDate}
              max={new Date().toISOString().split("T")[0]}
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              type="date"
              className="bg-gray-50 mb-3 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
            />
          </div>
          <div className="col-12 col-lg-2">
            <button
              className="btn btn-primary w-full my-2"
              onClick={handleGetReport}
            >
              Get Report
            </button>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center my-3">
          <h5 className="mb-0">Single LOCs</h5>
          <div className="d-flex justify-content-between align-items-center ">
            <h5 className="mb-0 mr-2">
              Assigned :{" "}
              <span className="text-primary font-bold">
                {data.assignedSingleLOCS}
              </span>
            </h5>
            <h5 className="mb-0 mr-2">
              Unassigned :{" "}
              <span className="text-primary font-bold">
                {data.unassignedSingleLOCS}
              </span>
            </h5>
            <button
              className="btn bg-primary text-white"
              onClick={() => handleDownload("single")}
            >
              Download
            </button>
          </div>
        </div>
        <div
          className="table-responsive  col-12 col-md-12 text-center mb-4"
          style={{ maxHeight: 400, overflowY: "auto" }}
        >
          <table
            className="table table-bordered"
            style={{ fontSize: "12px" }}
          >
            <thead className="sticky top-0">
              {/* <tr style={{ color: "#0987B1" }}>
                <th scope="col" colSpan={11}>
                  Assigned
                </th>
              </tr> */}
              <tr className="bg-primary" style={{ color: "white" }}>
                <th scope="col">Unique Asset ID</th>
                <th scope="col">Unique Asset Name</th>
                <th scope="col">FIELD 1</th>
                <th scope="col">FIELD 2</th>
                <th scope="col">FIELD 3</th>

                <th scope="col" colspan="2">
                  LOCATION
                </th>
                <th scope="col">LAST UPDATE</th>
                <th scope="col">LAST UPDATED BY</th>
                <th scope="col">CABLE STATUS</th>
                <th scope="col">NOTE</th>
              </tr>
            </thead>
            <tbody>
              {data.singleLOCs
                .filter((d) => d.origin_status === "assigned")
                .map((loc) => {
                  return (
                    <tr>
                      <td>{loc.unique_asset_id}</td>
                      <td>{loc.actual_asset_name}</td>
                      <td>{loc.field_1}</td>
                      <td>{loc.field_2}</td>
                      <td>{loc.field_3}</td>

                      <td>
                        <span style={{ fontSize: "9px" }}>
                          Latitude : {loc.origin_latitude}
                        </span>
                      </td>
                      <td>
                        <span style={{ fontSize: "9px" }}>
                          Longitude : {loc.origin_longitude}
                        </span>
                      </td>

                      <td style={{ minWidth: "200px" }}>
                        {new Date(loc.updatedAt).toUTCString()}
                      </td>
                      <td>{loc.User.email}</td>
                      <td>{loc.cable_status}</td>
                      <td>{loc.notes}</td>
                    </tr>
                  );
                })}
              <tr style={{ color: "#0987B1" }}>
                <th
                  scope="col"
                  colSpan={11}
                  style={{
                    borderTopWidth: "2px",
                    borderBottomWidth: "2px",
                    borderTopColor: "#000",
                    borderBottomColor: "#000",
                  }}
                >
                  Unassigned
                </th>
              </tr>

              {data.singleLOCs
                .filter((d) => d.origin_status === "unassigned")
                .map((loc) => {
                  return (
                    <tr>
                      <td>{loc.unique_asset_id}</td>
                      <td>{loc.actual_asset_name}</td>
                      <td>{loc.field_1}</td>
                      <td>{loc.field_2}</td>
                      <td>{loc.field_3}</td>

                      <td>
                        <span style={{ fontSize: "9px" }}>
                          Latitude : {loc.origin_latitude}
                        </span>
                      </td>
                      <td>
                        <span style={{ fontSize: "9px" }}>
                          Longitude : {loc.origin_longitude}
                        </span>
                      </td>

                      <td style={{ minWidth: "200px" }}>
                        {new Date(loc.updatedAt).toUTCString()}
                      </td>
                      <td>{loc.User.email}</td>
                      <td>{loc.cable_status}</td>
                      <td>{loc.notes}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        <div className="d-flex justify-content-between align-items-center mb-3">
          <h5 className="mb-0">Dual LOCs</h5>
          <div className="d-flex justify-content-between align-items-center ">
            <h5 className="mb-0 mr-2">
              Assigned :{" "}
              <span className="text-primary font-bold">
                {data.assignedDualLOCS}
              </span>
            </h5>
            <h5 className="mb-0 mr-2">
              Unassigned :{" "}
              <span className="text-primary font-bold">
                {data.unassignedDualLOCS}
              </span>
            </h5>
            <button
              className="btn  bg-primary text-white"
              onClick={() => handleDownload("dual")}
            >
              Download
            </button>
          </div>
        </div>
        <div
          className="table-responsive  col-12 col-md-12 text-center"
          style={{ maxHeight: 400, overflowY: "auto" }}
        >
          <table
            className="table table-bordered"
            style={{ fontSize: "12px" }}
          >
            <thead className="sticky top-0">
              {/* <tr style={{ color: "#0987B1" }}>
                <th scope="col" colSpan={17}>
                  Assigned
                </th>
              </tr> */}
              <tr className="bg-primary" style={{ color: "white" }}>
                <th>Route ID</th>
                <th scope="col">Origin</th>
                <th scope="col">FIELD 1</th>
                <th scope="col">FIELD 2</th>
                <th scope="col">FIELD 3</th>
                <th scope="col" colspan="2">
                  ORIGIN LOCATION
                </th>

                <th
                  scope="col"
                  style={{
                    borderLeft: "1px solid black",
                  }}
                >
                  DESTINATION
                </th>
                <th scope="col">FIELD 1</th>
                <th scope="col">FIELD 2</th>
                <th scope="col">FIELD 3</th>

                <th scope="col" colspan="2">
                  DESTINATION LOCATION
                </th>
                <th scope="col">LAST UPDATE</th>
                <th scope="col">LAST UPDATED BY</th>
                <th scope="col">CABLE STATUS</th>
                <th scope="col">NOTE</th>
              </tr>
            </thead>
            <tbody>
              {data.dualLOCs
                .filter((d) => d.origin_status === "assigned")
                .map((loc) => {
                  return (
                    <tr key={loc.loc_id}>
                      <td>{loc.unique_asset_id}</td>
                      <td>{loc.actual_asset_name}</td>
                      <td>{loc.field_1}</td>
                      <td>{loc.field_2}</td>
                      <td>{loc.field_3}</td>

                      <td>
                        <span style={{ fontSize: "9px" }}>
                          Latitude : {loc.origin_latitude}
                        </span>
                      </td>
                      <td>
                        <span style={{ fontSize: "9px" }}>
                          Longitude : {loc.origin_longitude}
                        </span>
                      </td>

                      <td style={{ borderLeft: "2px solid black" }}>
                        {loc.LOCDestination?.destination}
                      </td>
                      <td>{loc.LOCDestination?.destination_field_1}</td>
                      <td>{loc.LOCDestination?.destination_field_2}</td>
                      <td>{loc.LOCDestination?.destination_field_3}</td>
                      <td>
                        <span style={{ fontSize: "9px" }}>
                          Latitude : {loc.LOCDestination?.latitude}
                        </span>
                      </td>
                      <td>
                        <span style={{ fontSize: "9px" }}>
                          Longitude : {loc.LOCDestination?.longitude}{" "}
                        </span>
                      </td>

                      <td style={{ minWidth: "200px" }}>
                        {new Date(loc.updatedAt).toUTCString()}
                      </td>
                      <td>{loc.User.email}</td>
                      <td>{loc.cable_status}</td>
                      <td>{loc.notes}</td>
                    </tr>
                  );
                })}
              <tr style={{ color: "#0987B1" }}>
                <th
                  scope="col"
                  colSpan={17}
                  style={{
                    borderTopWidth: "2px",
                    borderBottomWidth: "2px",
                    borderTopColor: "#000",
                    borderBottomColor: "#000",
                  }}
                >
                  Unassigned
                </th>
              </tr>

              {data.dualLOCs
                .filter((d) => d.origin_status === "unassigned")
                .map((loc) => {
                  return (
                    <tr key={loc.loc_id}>
                      <td>{loc.unique_asset_id}</td>
                      <td>{loc.actual_asset_name}</td>
                      <td>{loc.field_1}</td>
                      <td>{loc.field_2}</td>
                      <td>{loc.field_3}</td>

                      <td>
                        <span style={{ fontSize: "9px" }}>
                          Latitude : {loc.origin_latitude}
                        </span>
                      </td>
                      <td>
                        <span style={{ fontSize: "9px" }}>
                          Longitude : {loc.origin_longitude}
                        </span>
                      </td>

                      <td style={{ borderLeft: "2px solid black" }}>
                        {loc.LOCDestination?.destination}
                      </td>
                      <td>{loc.LOCDestination?.destination_field_1}</td>
                      <td>{loc.LOCDestination?.destination_field_2}</td>
                      <td>{loc.LOCDestination?.destination_field_3}</td>
                      <td>
                        <span style={{ fontSize: "9px" }}>
                          Latitude : {loc.LOCDestination?.latitude}
                        </span>
                      </td>
                      <td>
                        <span style={{ fontSize: "9px" }}>
                          Longitude : {loc.LOCDestination?.longitude}{" "}
                        </span>
                      </td>

                      <td style={{ minWidth: "200px" }}>
                        {new Date(loc.updatedAt).toUTCString()}
                      </td>
                      <td>{loc.User.email}</td>
                      <td>{loc.cable_status}</td>
                      <td>{loc.notes}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ProjectConsumptionReport;
