import React, { Fragment, useEffect, useState } from "react";
import NavBar from "../../components/navbar/NavBar";
import Logs from "../../components/Logs";
import NotAuthPage from "../../pages/NotAuth/NotAuthPage";
import { notUser } from "../../util/roles";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  onFetchingUserLogs,
  onFetchingSpecificUser,
} from "../../store/LogsReducer/LogsReducer";
import fileDownload from "js-file-download";
import { url } from "../../constants";

const UserLogsPage = () => {
  const { role, token } = useSelector((state) => state.login);

  const dispatch = useDispatch();
  const [flag, setFlag] = useState(false);

  const { specificUser, userLogs, error, errorMsg } = useSelector(
    (state) => state.logs
  );

  const { id } = useParams();

  const handleDownload = () => {
    fetch(`${url}/api/users/${id}/logs/download`, {
      headers: {
        Authorization: `Bearer ${token}`,
        web: true,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        fileDownload(blob, `${specificUser.fullName}.xlsx`);
      });
  };

  useEffect(() => {
    dispatch(onFetchingSpecificUser(id, token));
    dispatch(onFetchingUserLogs(id, token));
  }, [dispatch, token, flag]);

  if (!notUser.includes(role)) return <NotAuthPage />;

  return (
    <Fragment>
      <NavBar />
      <Logs
        data={userLogs}
        name={specificUser.fullName}
        setFlag={setFlag}
        handleDownload={handleDownload}
        error={error}
        errorMsg={errorMsg}
      />
    </Fragment>
  );
};
export default UserLogsPage;
