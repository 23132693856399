import React, { useEffect, useState } from "react";
import "./navbar.css";
import axios from "axios";
import { url } from "../../constants";
import logo from "../../assets/images/logo.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { onLogout } from "../../store/Login/Login";
import { useDispatch, useSelector } from "react-redux";
import roles from "../../util/roles";

export default function NavBar({ withMargin = true }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.login.token);
  const fullName = useSelector((state) => state.login.fullName);
  const role = useSelector((state) => state.login.role);

  const [enabled2fa, setEnabled2fa] = useState(JSON.parse(localStorage.getItem("enabled2fa")));

  // const handleEnable2fa = async () => {
  //   await axios.post(`${url}/api/auth/2fa/enable`,{}, {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       "Content-Type": "application/json",
  //       web: true,
  //     },})

  //     setEnabled2fa(!enabled2fa);
  // }
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  return (
    <div>
      <div className="d-flex justify-content-center w-100 px-3 py-3 m-auto font-cairo font-semibold text-sm">
        <nav className="navbar navbar-expand-md navbar-light  w-100 m-auto text-center py-0 rounded">
          <div
            className="container-fluid"
            style={{ paddingLeft: "0px", paddingRight: "0px" }}
          >
            <Link to={"/"} className="navbar-brand">
              <img className="logo" src={logo} alt="logo" />
            </Link>
            <button
              className="custom-toggler navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarToggle"
              aria-controls="navbarsExample09"
              aria-expanded={!isNavCollapsed ? true : false}
              aria-label="Toggle navigation"
              onClick={handleNavCollapse}
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className={`${isNavCollapsed ? "collapse" : ""} navbar-collapse`}
              id="navbarToggle"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                {role !== roles.admin && role && (
                  <li className="nav-item">
                    <NavLink className="nav-link" to={"/globalidenetifiers"}>
                      HOME
                    </NavLink>
                  </li>
                )}
                {role !== roles.third_party && role !== roles.user && role && (
                  <li className="nav-item">
                    <NavLink className="nav-link" to={"/users"}>
                      ADMIN
                    </NavLink>
                  </li>
                )}

                {(role === roles.saas_admin ||
                  role === roles.super_admin ||
                  role === roles.admin) && (
                  <li className="nav-item">
                    <NavLink className="nav-link" to={"/third-party"}>
                      THIRD PARTIES
                    </NavLink>
                  </li>
                )}

                {role === roles.saas_admin && (
                  <li className="nav-item">
                    <NavLink className="nav-link" to={"/organizations"}>
                      ORGANIZATIONS
                    </NavLink>
                  </li>
                )}
                {role === roles.super_admin && (
                  <li className="nav-item">
                    <NavLink className="nav-link" to={"/organization"}>
                      ORGANIZATION
                    </NavLink>
                  </li>
                )}

                {role === roles.super_admin && (
                  <li className="nav-item">
                    <NavLink className="nav-link" to={`/consumption-report`}>
                      LOC Report
                    </NavLink>
                  </li>
                )}
              </ul>
              <ul className="navbar-nav">
                {token ? (
                  <>
                    <li className="nav-link active">Hello, {fullName}</li>
                       {/* <li className="border py-1 px-2 rounded-full flex items-center">
                        <label className="switch">
                          <input type="checkbox" checked={enabled2fa} onChange={() => handleEnable2fa()} />
                          <span className="slider round"></span>
                        </label>
                        <span className="ps-1 flex items-center">2FA</span>
                      </li> */}
                    <li onClick={() => dispatch(onLogout(navigate))}>
                      <NavLink
                        className="nav-link btn btn-danger rounded-1 px-3 py-1 ms-3"
                        style={{ color: "white" }}
                        to={"/home"}
                      >
                        Log out
                      </NavLink>
                    </li>
                  </>
                ) : (
                  <li onClick={() => dispatch(onLogout(navigate))}>
                    <NavLink
                      className="nav-link"
                      style={{ color: "#0987B1" }}
                      to={"/login"}
                    >
                      Login
                    </NavLink>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </nav>
      </div>
      {/* {withMargin && <hr />} */}
    </div>
  );
}
