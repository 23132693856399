import React, { useState } from "react";

import Modal from "react-modal";
import { Link } from "react-router-dom";

import { PropagateLoader } from "react-spinners";
import { css } from "@emotion/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./AllOrganizations.module.css";
import useOrganizationsContainer from "../../containers/useOrganizationsContainer";
import FormikInput from "../formik/FormikInput";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "20px"
  },
};

export default function AllOrganizations() {
  const {
    openForm,
    setOpenForm,
    openDelete,
    setOpenDelete,
    organizations,
    isEdit,
    formik,
    handleOpenCreate,
    submitLoading,
    handleOpenDelete,
    handleOpenEdit,
    handleDelete,
    handleSearch,
  } = useOrganizationsContainer();

  const [isSearch, setIsSearch] = useState(false);
  return (
    <>
      <div className="container">
        <ToastContainer />

        <div className="row">
          <div className="col-12 mt-4  m-auto row">
            <div
              className="my-4 col-6 fs-3 font-semibold text-uppercase"
              style={{ textTransform: "uppercase" }}
            >
              Organizations
            </div>
            <div className="row col-6 d-flex justify-content-end my-4">
              <div className="col-6 col-md-4 w-100 d-flex justify-content-end">
                <button className="btn btn-primary py-3" onClick={handleOpenCreate}>
                  Add new Organization
                </button>
              </div>
            </div>
            <div className="w-full m-auto my-4" style={{ position: "relative" }}>
              <i
                className="far fa-search text-dark"
                style={{ position: "absolute", top: "12px", left: "2%" }}
              ></i>
              <input
                type="text"
                className="form-control border-1 py-2 border-black rounded-3"
                name="search"
                placeholder="Search"
                onChange={(e) => {
                  handleSearch(e.target.value);
                  setIsSearch(true);
                }}
                style={{ paddingLeft: "35px" }}
              />
            </div>
          </div>
        </div>
        <div className="row col-12 m-auto">
          <div className="col-12 m-auto pe-0">
            <div className="rounded-t-lg bg-primary py-3 text-white font-semibold">
              <div className="row">
                <div className="col-6 d-flex ps-4">
                  Name
                </div>
                <div className="col-6 d-flex justify-center ">
                  Actions
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row col-12 m-auto" style={{ maxHeight: "42vh", overflowY: "scroll", overflowX: "hidden" }}>
          {organizations && organizations.length > 0 ? (
            organizations.map((organization) => {
              return (
                <div
                  className="col-12 m-auto pe-0"
                  key={organization.id}
                >
                  <Link
                    style={{ textDecoration: "none" }}
                    to={"/consumption-report/" + organization.id}
                  >
                    <div className="border py-2">
                      <div className="row">
                        <div className="col-6 d-flex justify-content-start ps-4">
                          <div>{organization.name}</div>
                        </div>

                        <div className="col-6 d-flex justify-content-start ps-4">
                          <button 
                            className="text-white no-underline py-2 px-4 bg-[#65CC57] rounded-lg border-0 ms-4 me-4"
                            onClick={(e) => {
                              e.preventDefault();
                              handleOpenEdit(organization);
                            }}
                          >
                          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
            >
              <path
                d="M9.16675 3.83301H3.33341C2.89139 3.83301 2.46746 4.0086 2.1549 4.32116C1.84234 4.63372 1.66675 5.05765 1.66675 5.49967V17.1663C1.66675 17.6084 1.84234 18.0323 2.1549 18.3449C2.46746 18.6574 2.89139 18.833 3.33341 18.833H15.0001C15.4421 18.833 15.866 18.6574 16.1786 18.3449C16.4912 18.0323 16.6667 17.6084 16.6667 17.1663V11.333"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15.4167 2.5832C15.7483 2.25168 16.1979 2.06543 16.6667 2.06543C17.1356 2.06543 17.5852 2.25168 17.9167 2.5832C18.2483 2.91472 18.4345 3.36436 18.4345 3.8332C18.4345 4.30204 18.2483 4.75168 17.9167 5.0832L10.0001 12.9999L6.66675 13.8332L7.50008 10.4999L15.4167 2.5832Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span className="ml-2">Edit Organization</span>
                          </button>
                          {/* <i
                            className="fas fa-pencil-alt text-secondary mx-2 mt-1"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#editIdentifier"
                            onClick={(e) => {
                              e.preventDefault();
                              handleOpenEdit(organization);
                            }}
                          ></i> */}
                          <button 
                            className="text-white py-2 px-4 bg-[#DC3545] rounded-lg border-0 ms-4"
                            onClick={(e) => {
                              e.preventDefault();
                              handleOpenDelete(organization);
                            }}
                            >
                          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M3 6H5H21"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10 11V17"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14 11V17"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span className="ml-2">Delete Organization</span>
                          </button>
                          {/* <i
                            type="button"
                            className="far fa-trash-alt text-danger mx-2 mt-1"
                            onClick={(e) => {
                              e.preventDefault();
                              handleOpenDelete(organization);
                            }}
                          ></i> */}
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })
          ) : (
            <div style={{ textAlign: "center" }}>
               {isSearch ? "organization doesn't exist" : "No Organizations Added Yet. "}
            </div>
          )}
        </div>

        
      </div>

      <Modal
        isOpen={openForm}
        style={customStyles}
        onRequestClose={() => setOpenForm(false)}
        contentLabel="Example Modal"
        className={styles.Modal}
        overlayClassName={styles.Overlay}
        ariaHideApp={false}
      >
        <div className="container modal-body">
          <div className="row">
            <div className="col-10 m-auto">
              <h5 className="text-center my-3">{`${
                isEdit ? "Edit" : "Create"
              } Organization`}</h5>
              <form onSubmit={formik.handleSubmit}>
                <FormikInput formik={formik} name="name" />
                {!isEdit && (
                  <>
                    <FormikInput
                      formik={formik}
                      name="fullName"
                      placeholder="Full Name"
                    />
                    <FormikInput formik={formik} name="email" />
                  </>
                )}

                <div className="d-flex justify-content-center my-3">
                  <button
                    type="submit"
                    className="btn btn-primary w-25 mx-2 hamada"
                    style={{ height: "40px" }}
                  >
                    {submitLoading ? (
                      <PropagateLoader
                        color={"#fff"}
                        css={override}
                        size={10}
                      />
                    ) : isEdit ? (
                      "Save"
                    ) : (
                      "Create"
                    )}
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary mx-2"
                    onClick={() => setOpenForm(false)}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>

      {/* Delete Modal */}
      <Modal
        isOpen={openDelete}
        style={customStyles}
        onRequestClose={() => setOpenDelete(false)}
        contentLabel="Delete Modal"
        className={styles.Modal}
        overlayClassName={styles.Overlay}
        ariaHideApp={false}
      >
        <div className="container modal-body">
          <div className="row">
            <div className="col-10 m-auto">
              <h5 className="text-center my-3">
                Are you sure you want to delete this Organization ?
              </h5>

              <div className="d-flex justify-content-center my-3">
                <button
                  type="button"
                  className="btn btn-danger mx-2"
                  onClick={handleDelete}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="btn btn-secondary mx-2"
                  onClick={() => setOpenDelete(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* End Delete Modal */}
    </>
  );
}
