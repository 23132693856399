import React, { Fragment } from "react";
import NavBar from "../../components/navbar/NavBar";
import ConsumptionReport from "../../components/consumptionReport/ConsumptionReport";
import { notSuperAdminOrSaasAdmin } from "../../util/roles";
import { useSelector } from "react-redux";
import NotAuthPage from "../NotAuth/NotAuthPage";
import { useParams } from "react-router-dom";

const ConsumptionReportPage = () => {
  const { role, org_id } = useSelector((state) => state.login);
  const { id } = useParams();

  if (notSuperAdminOrSaasAdmin.includes(role)) return <NotAuthPage />;

  const organizationId = org_id || id;

  return (
    <Fragment>
      <NavBar />
      <ConsumptionReport id={organizationId} />
    </Fragment>
  );
};
export default ConsumptionReportPage;
