import { useState, useEffect } from "react";
import axios from "axios";
import { useFormik } from "formik";
import useOrganizations from "../hooks/useOrganizations";
import validationSchema from "../validations/organization";
import { url } from "../constants";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const useOrganizationsContainer = () => {
  const { loading: organizationsLoading, organizations: data } =
    useOrganizations();
  const { token } = useSelector((state) => state.login);

  const [organizations, setOrganizations] = useState([]);
  const [searchedOrganizations, setSearchedOrganizations] = useState([]);

  const [openForm, setOpenForm] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState({});

  const [initialValues, setInitialValues] = useState({
    name: "",
    email: "",
    fullName: "",
  });

  const handleSubmit = async (values) => {
    setSubmitLoading(true);

    try {
      if (isEdit) {
        const result = await axios.patch(
          `${url}/api/organizations/${selectedOrganization.id}`,
          { name: values.name },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              web: true,
            },
          }
        );
        let newOrg = [...organizations];

        const index = organizations.findIndex(
          (o) => o.id === selectedOrganization.id
        );
        newOrg.splice(index, 1, result.data.organization);
        setOrganizations(newOrg);
        setSearchedOrganizations(newOrg);
        toast(result.data.message);
      } else {
        const result = await axios.post(
          `${url}/api/organizations`,
          { name: values.name, fullName: values.fullName, email: values.email },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              web: true,
            },
          }
        );
        setOrganizations([...organizations, result.data.organization]);
        setSearchedOrganizations([...organizations, result.data.organization]);
        toast(result.data.message);
      }

      setOpenForm(false);
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        toast.error(error.response.data.error);
      } else {
        toast.error("something went wrong");
      }
    } finally {
      setSubmitLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: () => validationSchema(isEdit),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    if (data) {
      setOrganizations(data);
      setSearchedOrganizations(data);
    }
  }, [data]);

  const loading = organizationsLoading;

  const handleOpenCreate = () => {
    setOpenForm(true);
    setIsEdit(false);
    setInitialValues({
      name: "",
      email: "",
      fullName: "",
    });
  };

  const handleOpenDelete = (organization) => {
    setOpenDelete(true);
    setSelectedOrganization(organization);
  };

  const handleOpenEdit = (organization) => {
    setOpenForm(true);
    setIsEdit(true);
    setSelectedOrganization(organization);
    setInitialValues({ ...initialValues, name: organization.name });
  };

  const handleDelete = async () => {
    setSubmitLoading(true);
    try {
      const result = await axios.delete(
        `${url}/api/organizations/${selectedOrganization.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            web: true,
          },
        }
      );
      setOrganizations(
        organizations.filter((o) => o.id !== selectedOrganization.id)
      );
      setSearchedOrganizations(
        organizations.filter((o) => o.id !== selectedOrganization.id)
      );
      toast(result.message);
      setOpenDelete(false);
    } catch (error) {
      toast(error.message);
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleSearch = (value) => {
    if (value.trim() !== "") {
      const arr = [...organizations].filter((o) =>
        o.name.toLowerCase().includes(value.toLowerCase())
      );
      setSearchedOrganizations(arr);
    } else {
      setSearchedOrganizations(organizations);
    }
  };
  return {
    organizations: searchedOrganizations,
    openDelete,
    setOpenDelete,
    openForm,
    setOpenForm,
    loading,
    formik,
    isEdit,
    handleOpenCreate,
    submitLoading,
    selectedOrganization,
    handleOpenDelete,
    handleOpenEdit,
    handleDelete,
    handleSearch,
  };
};

export default useOrganizationsContainer;
