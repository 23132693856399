import React, { Fragment, useEffect, useState } from "react";
import NavBar from "../../components/navbar/NavBar";
import Logs from "../../components/Logs";
import NotAuthPage from "../NotAuth/NotAuthPage";
import { notAdmin } from "../../util/roles";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { onFetchingProjectLogs } from "../../store/LogsReducer/LogsReducer";

import { onFetchingSpecificProject } from "../../store/Projects/ProjectsReducer";
import fileDownload from "js-file-download";
import { url } from "../../constants";

const ProjectLogsPage = () => {
  const role = useSelector((state) => state.login.role);
  const token = useSelector((state) => state.login.token);

  const dispatch = useDispatch();
  const [flag, setFlag] = useState(false);

  const { projectLogs } = useSelector((state) => state.logs);

  const { specificProject } = useSelector((state) => state.projects);

  const { id } = useParams();

  const handleDownload = () => {
    fetch(`${url}/api/projects/${id}/logs/download`, {
      headers: {
        Authorization: `Bearer ${token}`,
        web: true,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        fileDownload(blob, `${specificProject.name}.xlsx`);
      });
  };

  useEffect(() => {
    dispatch(onFetchingSpecificProject(id, token));
    dispatch(onFetchingProjectLogs(id, token));
  }, [dispatch, token, flag]);

  if (!notAdmin.includes(role)) return <NotAuthPage />;

  return (
    <Fragment>
      <NavBar />
      <Logs
        data={projectLogs}
        name={specificProject.name}
        setFlag={setFlag}
        handleDownload={handleDownload}
      />
    </Fragment>
  );
};
export default ProjectLogsPage;
