import React, { Fragment } from "react";
import OTPSignin from "../../components/OTPSignin/OTPSignin";

const OTPSigninPage = () => {
  return (
    <Fragment>
         <OTPSignin />
    </Fragment>
    );
}
export default  OTPSigninPage;