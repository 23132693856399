import {Polyline} from "@react-google-maps/api";


const Line = ({connection,index}) => {
 
    if(!connection.origin || !connection.destination) return <></>
    return ( <Polyline
        key={index}
        path={[
          {
            lat: connection.origin.latitude,
            lng: connection.origin.longitude,
          },
          {
            lat: connection.destination.latitude,
            lng: connection.destination.longitude,
          },
        ]}
        options={{
          strokeColor: connection.status === 'assigned' ?'#36B721':'#c22f2f',
          strokeOpacity: 1,
          strokeWeight: 2,
        }}
      /> );
}
 
export default Line;