import { useState, useEffect } from "react";
import axios from "axios";
import { useFormik } from "formik";
import validationSchema from "../validations/organization";
import { url } from "../constants";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const useOrganizationContainer = () => {
  const { token, org_id } = useSelector((state) => state.login);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [organization, setOrganization] = useState({});
  const [categories, setCategories] = useState([]);
  const [initialValues, setInitialValues] = useState({ name: "" });
  const [categoryInitialValues, setCategoryInitialValues] = useState({name: "", image: "", fields: "", organization: "", LOC_type: "single"});

  const handleOrganization = async () => {
    setLoading(true);
    try {
      const result = await axios.get(`${url}/api/organizations/${org_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          web: true,
        },
      });
      toast(result.message);
      setOrganization(result.data.organization);
      setCategories(result.data.categories);
      setInitialValues({ name: result.data.organization.name });
    } catch (error) {
      toast(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    setSubmitLoading(true);
    try {
      const result = await axios.patch(
        `${url}/api/organizations/${org_id}`,
        { name: values.name },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            web: true,
          },
        }
      );

      toast(result.message);
      setOrganization({ ...organization, name: values.name });
    } catch (error) {
      toast(error.message);
    } finally {
      setSubmitLoading(false);
    }
  };

  const updateCategories = async () => {
    try {
      const result = await axios.get(`${url}/api/organizations/${org_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          web: true,
        },
      });
      setCategories(result.data.categories);
    } catch (error) {
      toast.error("Failed to update categories");
    }
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: () => validationSchema(true),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    handleOrganization();
  }, []);

  return {
    organization,
    categories,
    updateCategories,
    loading,
    formik,
    submitLoading,
  };
};

export default useOrganizationContainer;
