import React, { Fragment } from "react";
import NavBar from "../../components/navbar/NavBar";
import Organization from "../../components/organizations/Organization";
import NotAuthPage from "../../pages/NotAuth/NotAuthPage";

import { notSuperAdmin } from "../../util/roles";
import { useSelector } from "react-redux";

const Organizations = () => {
  const role = useSelector((state) => state.login.role);
  if (notSuperAdmin.includes(role)) return <NotAuthPage />;

  return (
    <Fragment>
      <NavBar />
      <Organization />
    </Fragment>
  );
};
export default Organizations;
