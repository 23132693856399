import React, { Fragment } from "react";
import NavBar from "../../components/navbar/NavBar";
import LocationConsumptionReport from "../../components/project/LocationConsumptionReport";
// import { notSuperAdminOrSaasAdmin } from "../../util/roles";
// import { useSelector } from "react-redux";
// import NotAuthPage from "../NotAuth/NotAuthPage";
import { useParams } from "react-router-dom";

const LocationConsumptionReportPage = () => {
//   const { role, org_id } = useSelector((state) => state.login);
  const { id } = useParams();

//   if (notSuperAdminOrSaasAdmin.includes(role)) return <NotAuthPage />;

  const projectId = id;

  return (
    <Fragment>
      <NavBar />
      <LocationConsumptionReport id={projectId} />
    </Fragment>
  );
};
export default LocationConsumptionReportPage;