import React, { Fragment } from "react";
import NavBar from "../../components/navbar/NavBar";
import AllThirdParties from "../../components/thirdParties/AllThirdParties";
import NotAuthPage from "../../pages/NotAuth/NotAuthPage";

import { notSuperAdminOrSaasAdminOrAdmin } from "../../util/roles";
import { useSelector } from "react-redux";

const ThirdParties = () => {
  const role = useSelector((state) => state.login.role);

  if (notSuperAdminOrSaasAdminOrAdmin.includes(role)) return <NotAuthPage />;

  return (
    <Fragment>
      <NavBar />
      <AllThirdParties />
    </Fragment>
  );
};
export default ThirdParties;
