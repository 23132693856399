import { useState } from "react";
import { ReactSortable } from "react-sortablejs";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

import { PropagateLoader } from "react-spinners";
import { css } from "@emotion/react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useOrganizationContainer from "../../containers/useOrganizationContainer";
import FormikInput from "../formik/FormikInput";
import Modal from "react-modal";
import { useSelector } from "react-redux";

import { notAuthError, url } from "../../constants";

import Tooltip from "../Tooltip";
import { ReactComponent as EditIcon } from "../../ui/icons/EditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../ui/icons/DeleteIcon.svg";
import styles from "../global idenetifiers/GlobalIdentifiers.module.css";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "20px",
  },
};

export default function Organization() {
  const { token, userId, role } = useSelector((state) => state.login);
  const { organization, categories, updateCategories, formik, loading, submitLoading } =
    useOrganizationContainer();
  const [DeleteIsOpen, setDeleteIsOpen] = useState(false);
  const [AddIsOpen, setAddIsOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [addedCategoryName, setAddedCategoryName] = useState("");
  const [addedCategoryImage, setAddedCategoryImage] = useState("");
  const [addedCategoryType, setAddedCategoryType] = useState("single");
  const [addedFields, setAddedFields] = useState([]);
  const [addedDestinationFields, setAddedDestinationFields] = useState([]);
  const [fieldInputValue, setFieldInputValue] = useState("");
  const [destinationFieldInputValue, setDestinationFieldInputValue] = useState("");

  const storage = getStorage();

  const storageRef = ref(storage, `categories/${organization.id}/${addedCategoryImage.name}`);
  const handleUploadImage = async (e) => {
    const file = e.target.files[0];
    let metadata = {};
  
    if (file.type === 'image/svg+xml') {
      metadata = {
        contentType: 'image/svg+xml'
      };
    } else if (file.type === 'image/jpeg' || file.type === 'image/png') {
      metadata = {
        contentType: file.type
      };
    } else {
      console.error('Unsupported file type');
      return;
    }
    try {
      await uploadBytes(storageRef, file, metadata);
      const downloadURL = await getDownloadURL(storageRef);
      setAddedCategoryImage(downloadURL);
    } catch (error) {
      console.error('Error uploading image', error);
    }
  }
  const handleDeleteCategory = (category) => {
    fetch(`${url}/api/organizations/categories/${category.id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        web: true,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        if (resData.error) {
          if (resData.error === notAuthError) {
            toast("you session expired, please login again.");
          } else {
            toast.error(resData.error);
          }
        }

        if (resData.error && resData.error[0]) {
          toast(resData.error[0].message);
        }
        if (resData.message) {
          toast.success(resData.message);
          updateCategories();
        }
      });
  }
  const handleCreateCategory = () => {
    let errors = 0;
    if (addedCategoryName === '') {
      toast.error('category name is required');
      errors++;
    }
    if (addedFields.length === 0) {
      toast.error('category must have fields');
      errors++;
    }
    if (addedCategoryType === 'dual' && addedDestinationFields.length === 0) {
      toast.error('category of type dual must have destination fields');
      errors++;
    }
    if (errors > 0) return;
    const newCategory = {
      name: addedCategoryName,
      image: addedCategoryImage,
      LOC_type: addedCategoryType,
      fields: addedFields,
      organization: organization.id
    }
    if (addedCategoryType === "dual") {
      newCategory.destination_fields = addedDestinationFields;
    }
    fetch(`${url}/api/organizations/categories`, {
      method: "POST",
      headers: { 
        Authorization: `Bearer ${token}`,
        web: true,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newCategory),
    }).then((res) => res.json())
    .then(resData => {
      toast.success(resData.message);
      setAddIsOpen(false);
      // Reset form fields
      setAddedCategoryName("");
      setAddedCategoryImage("");
      setAddedCategoryType("single");
      setAddedFields([]);
      updateCategories();
    }).catch(err => {
      toast.error(err);
    });
  };
  return (
    <div className="container">
      <ToastContainer />
      <div className="row">
        <div className="col-md-8 offset-md-2 offset-0 col-12">
          <h5 className="text-center my-3">{`Edit Organization (${
            !loading && organization.name
          })`}</h5>

          <form onSubmit={formik.handleSubmit}>
            <FormikInput formik={formik} name="name" />
            <div className="d-flex justify-content-center my-3">
              <button
                type="submit"
                className="btn btn-primary w-25 mx-2 hamada"
                style={{ height: "40px" }}
              >
                {submitLoading ? (
                  <PropagateLoader color={"#fff"} css={override} size={10} />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </form>

          <hr></hr>
            <div>
              <div className="text-end">
                <h2 className="text-center mb-4">LOC Categories</h2>
                <button 
                  className="btn btn-primary rounded-2 mb-2"
                  onClick={(e) => {
                    setAddIsOpen(true);
                  }}
                  >
                    Add Category
                  </button>
              </div>
              <h3>Single LOC Categories</h3>
              <table className="table table-bordered">
                <thead className="sticky top-0 z-50 ">
                  <tr className="bg-primary text-white rounded-t-lg">
                    <th scope="col" className="text-center">Name</th>
                    <th scope="col" className="text-center">Image</th>
                    <th scope="col" className="text-center">Fields</th>
                    <th scope="col" className="text-center">
                      Actions
                    </th>
                  </tr>
                </thead>

                {categories && categories.filter(category => category.LOC_type === 'single').length > 0 ? (
                  categories.filter(category => category.LOC_type === 'single').map(category => {
                    return (
                      <tbody>
                        <tr className="text-center" key={category.category_id}>
                          <td>{category.name}</td>
                          <td className="w-fit">
                            <div className="flex justify-center">
                              <img
                                src={category.image || "https://firebasestorage.googleapis.com/v0/b/loc-flutter-app.appspot.com/o/categories%2Fsettings-svgrepo-com.svg?alt=media&token=5055c3d7-1499-4fd4-bb01-5c3ef56b72fd"}
                                alt={category.name}
                                className="h-8"
                              />
                            </div>
                          </td>

                          <td style={{maxWidth: "300px"}}>
                            <div 
                              className="flex flex-wrap justify-center"
                            >
                              {category.fields.map(field => (
                                <span className="d-inline-block text-sm px-2 py-1 m-1 text-white font-semi-bold bg-secondary rounded rounded-pill">{field}</span>
                              ))}
                            </div>
                          </td>
                          <td className="flex flex-row text-center justify-center min-w-fit items-center my-1  gap-2 ">
                            {/* <Tooltip text="Edit category">
                              <button
                                className="btn z-10 p-0 m-0"
                                type="button"
                                onClick={() => {
                                }}
                              >
                                <EditIcon className="cursor-pointer" />
                              </button>
                            </Tooltip> */}
                            <Tooltip text="Delete category">
                              <button
                                className="btn p-0 m-0"
                                type="button"
                                onClick={() => {
                                  setDeleteIsOpen(true);
                                  setSelectedCategory(category);
                                }}
                              >
                                <DeleteIcon className="cursor-pointer" />
                              </button>
                            </Tooltip>
                          </td>
                        </tr>
                      </tbody>
                    )})): (
                      <tbody>
                      <tr className="text-center">
                        <td>default</td>
                        <td className="w-fit">
                          <div className="flex justify-center">
                            <img
                              src={"https://firebasestorage.googleapis.com/v0/b/loc-flutter-app.appspot.com/o/categories%2Fsettings-svgrepo-com.svg?alt=media&token=5055c3d7-1499-4fd4-bb01-5c3ef56b72fd"}
                              alt={"default"}
                              className="h-8"
                            />
                          </div>
                        </td>

                        <td style={{maxWidth: "300px"}}>
                          <div 
                            className="flex flex-wrap justify-center"
                          >
                            <span className="d-inline-block text-sm px-2 py-1 m-1 text-white font-semi-bold bg-secondary rounded rounded-pill">field 1</span>
                            <span className="d-inline-block text-sm px-2 py-1 m-1 text-white font-semi-bold bg-secondary rounded rounded-pill">field 2</span>
                            <span className="d-inline-block text-sm px-2 py-1 m-1 text-white font-semi-bold bg-secondary rounded rounded-pill">field 3</span>
                          </div>
                        </td>
                        <td className="flex flex-row text-center justify-center min-w-fit items-center my-1  gap-2 ">
                        </td>
                      </tr>
                    </tbody>
                    )}
              </table>

              <h3>Dual LOC Categories</h3>
              <table className="table table-bordered">
                <thead className="sticky top-0 z-50 ">
                  <tr className="bg-primary text-white rounded-t-lg">
                    <th scope="col" className="text-center">Name</th>
                    <th scope="col" className="text-center">Image</th>
                    <th scope="col" className="text-center">Origin Fields</th>
                    <th scope="col" className="text-center">Destination Fields</th>
                    <th scope="col" className="text-center">
                      Actions
                    </th>
                  </tr>
                </thead>

                {categories && categories.filter(category => category.LOC_type === 'dual').length > 0 ? (
                  categories.filter(category => category.LOC_type === 'dual').map(category => {
                    return (
                      <tbody>
                        <tr className="text-center" key={category.category_id}>
                          <td>{category.name}</td>
                          <td className="w-fit">
                            <div className="flex justify-center">
                              <img
                                src={category.image || "https://firebasestorage.googleapis.com/v0/b/loc-flutter-app.appspot.com/o/categories%2Fsettings-svgrepo-com.svg?alt=media&token=5055c3d7-1499-4fd4-bb01-5c3ef56b72fd"}
                                alt={category.name}
                                className="h-8"
                              />
                            </div>
                          </td>

                          <td style={{maxWidth: "300px"}}>
                            <div 
                              className="flex flex-wrap justify-center"
                            >
                              {category.fields.map(field => (
                                <span className="d-inline-block text-sm px-2 py-1 m-1 text-white font-semi-bold bg-secondary rounded rounded-pill">{field}</span>
                              ))}
                            </div>
                          </td>
                          <td style={{maxWidth: "300px"}}>
                            <div 
                              className="flex flex-wrap justify-center"
                            >
                              {category.destination_fields?.map(field => (
                                <span className="d-inline-block text-sm px-2 py-1 m-1 text-white font-semi-bold bg-secondary rounded rounded-pill">{field}</span>
                              ))}
                            </div>
                          </td>
                          <td className="flex flex-row text-center justify-center min-w-fit items-center my-1  gap-2 ">
                            {/* <Tooltip text="Edit category">
                              <button
                                className="btn z-10 p-0 m-0"
                                type="button"
                                onClick={() => {
                                }}
                              >
                                <EditIcon className="cursor-pointer" />
                              </button>
                            </Tooltip> */}
                            <Tooltip text="Delete category">
                              <button
                                className="btn p-0 m-0"
                                type="button"
                                onClick={() => {
                                  setDeleteIsOpen(true);
                                  setSelectedCategory(category);
                                }}
                              >
                                <DeleteIcon className="cursor-pointer" />
                              </button>
                            </Tooltip>
                          </td>
                        </tr>
                      </tbody>
                    )})): (
                      <tbody>
                      <tr className="text-center">
                        <td>default</td>
                        <td className="w-fit">
                          <div className="flex justify-center">
                            <img
                              src={"https://firebasestorage.googleapis.com/v0/b/loc-flutter-app.appspot.com/o/categories%2Fsettings-svgrepo-com.svg?alt=media&token=5055c3d7-1499-4fd4-bb01-5c3ef56b72fd"}
                              alt={"default"}
                              className="h-8"
                            />
                          </div>
                        </td>

                        <td style={{maxWidth: "300px"}}>
                          <div 
                            className="flex flex-wrap justify-center"
                          >
                            <span className="d-inline-block text-sm px-2 py-1 m-1 text-white font-semi-bold bg-secondary rounded rounded-pill">field 1</span>
                            <span className="d-inline-block text-sm px-2 py-1 m-1 text-white font-semi-bold bg-secondary rounded rounded-pill">field 2</span>
                            <span className="d-inline-block text-sm px-2 py-1 m-1 text-white font-semi-bold bg-secondary rounded rounded-pill">field 3</span>
                          </div>
                        </td>

                        <td style={{maxWidth: "300px"}}>
                          <div 
                            className="flex flex-wrap justify-center"
                          >
                            <span className="d-inline-block text-sm px-2 py-1 m-1 text-white font-semi-bold bg-secondary rounded rounded-pill">field 1</span>
                            <span className="d-inline-block text-sm px-2 py-1 m-1 text-white font-semi-bold bg-secondary rounded rounded-pill">field 2</span>
                            <span className="d-inline-block text-sm px-2 py-1 m-1 text-white font-semi-bold bg-secondary rounded rounded-pill">field 3</span>
                          </div>
                        </td>

                        <td className="flex flex-row text-center justify-center min-w-fit items-center my-1  gap-2 ">
                        </td>
                      </tr>
                    </tbody>
                    )}
              </table>
            </div>
        </div>
      </div>

      <Modal
        isOpen={AddIsOpen}
        style={customStyles}
        onRequestClose={() => setAddIsOpen(false)}
        contentLabel="Add Modal"
        className={styles.Modal}
        overlayClassName={styles.Overlay}
        ariaHideApp={false}
      >
        <div className="container modal-body">
          <div className="row">
            <div className="col-10 m-auto">
              <h5 className="text-center my-3">Add Category</h5>

              <form onSubmit={() => handleCreateCategory()}>
                <div>
                <input
                  type="text"
                  className="form-control p-2"
                  id="categoryName"
                  name="categoryName"
                  placeholder="Category Name"
                  style={
                  {borderColor: "#ccc"}
                  }
                  value={addedCategoryName}
                  onChange={(e) => setAddedCategoryName(e.target.value)}
                />
                <div className="flex my-2 border border-grey rounded-lg ps-2 align-items-center">
                  <label htmlFor="image" className="col-3 text-secondary">Category Image</label>
                  <input type="file" className="form-control" id="image" name="image" onChange={(e) => handleUploadImage(e)} placeholder="category image" accept="image/*" />
                </div>

                <select
                  id="select"
                  className="form-select mt-1 p-2"
                  value={addedCategoryType}
                  onChange={(e) => setAddedCategoryType(e.target.value)}
                >
                  <option value="single">single</option>
                  <option value="dual">dual</option>
                </select>
                </div>
              </form>
              <div className="bg-light rounded-lg p-3 my-4">
                <h5 className="">{addedCategoryType === 'single' ? "" : "Origin "}Fields</h5>

                  <div 
                    className="border border-secondary rounded-lg p-1"
                      style={{maxHeight: "200px", minHeight: "100px", overflowY: "scroll"}}
                  >
                    <ReactSortable list={addedFields} setList={setAddedFields}>
                      {addedFields.map((field, index) => (
                        <span 
                          className="d-inline-block text-sm px-2 py-1 m-1 text-white font-semi-bold bg-secondary rounded rounded-pill"
                          style={{cursor: "grab"}}
                          >
                            {field} |
                            &nbsp;
                            <span
                              className="font-bold hover"
                              style={{cursor: "pointer"}}
                              onClick={() => {
                                setAddedFields(addedFields.filter((_, i) => i !== index));
                              }}
                            >
                              x
                            </span>
                        </span>
                          ) || "No fields")}
                    </ReactSortable>
                  </div>

                  <div className="flex mt-2">
                      <input
                        type="text"
                        className="form-control p-2"
                        placeholder="Field Name"
                        style={{borderColor: "#ccc"}}
                        onChange={(e) => setFieldInputValue(e.target.value)}
                        onKeyUp={(e) => {
                          if (e.key === "Enter" && e.target.value !== "") {
                            setAddedFields([...addedFields, fieldInputValue]);
                            setFieldInputValue("");
                          } else {
                            e.preventDefault();
                          }
                          }}
                        value={fieldInputValue}
                      />
                      <button 
                        className="btn btn-primary ms-1 col-2" 
                        onClick={() => {
                          if (fieldInputValue === '') return;
                          setAddedFields([...addedFields, fieldInputValue]);
                          setFieldInputValue("");
                          }}
                        >
                          + field
                      </button>
                    </div>
              </div>

              {addedCategoryType === "dual" && (
                <div className="bg-light rounded-lg p-3 my-4">
                  <h5 className="">Destination Fields</h5>
  
                    <div 
                      className="border border-secondary rounded-lg p-1"
                        style={{maxHeight: "200px", minHeight: "100px", overflowY: "scroll"}}
                    >
                      <ReactSortable list={addedDestinationFields} setList={setAddedDestinationFields}>
                        {addedDestinationFields.map((field, index) => (
                          <span 
                            className="d-inline-block text-sm px-2 py-1 m-1 text-white font-semi-bold bg-secondary rounded rounded-pill"
                            style={{cursor: "grab"}}
                            >
                              {field} |
                              &nbsp;
                              <span
                                className="font-bold hover"
                                style={{cursor: "pointer"}}
                                onClick={() => {
                                  setAddedDestinationFields(addedDestinationFields.filter((_, i) => i !== index));
                                }}
                              >
                                x
                              </span>
                          </span>
                            ) || "No fields")}
                      </ReactSortable>
                    </div>

                    <div className="flex mt-2">
                      <input
                        type="text"
                        className="form-control p-2"
                        placeholder="Field Name"
                        style={{borderColor: "#ccc"}}
                        onChange={(e) => {
                          setDestinationFieldInputValue(e.target.value);
                        }}
                        onKeyUp={(e) => {
                          if (e.key === "Enter" && e.target.value !== "") {
                            setAddedDestinationFields([...addedDestinationFields, destinationFieldInputValue]);
                            setDestinationFieldInputValue("");
                          } else {
                            e.preventDefault();
                          }
                          }}
                        value={destinationFieldInputValue}
                      />
                      <button 
                        className="btn btn-primary ms-1 col-2" 
                        onClick={() => {
                          if (destinationFieldInputValue === '') return;
                          setAddedDestinationFields([...addedDestinationFields, destinationFieldInputValue]);
                          setDestinationFieldInputValue("");
                          }}
                        >
                          + field
                      </button>
                    </div>
                </div>
              )}

              <div className="d-flex justify-content-center my-3">
                <button
                  type="button"
                  className="btn btn-success mx-2"
                  onClick={() => {
                    handleCreateCategory();
                  }}
                >
                  Add
                </button>
                <button
                  type="button"
                  className="btn btn-secondary mx-2"
                  onClick={() => setAddIsOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={DeleteIsOpen}
        style={customStyles}
        onRequestClose={() => setDeleteIsOpen(false)}
        contentLabel="Delete Modal"
        className={styles.Modal}
        overlayClassName={styles.Overlay}
        ariaHideApp={false}
      >
        <div className="container modal-body">
          <div className="row">
            <div className="col-10 m-auto">
              <h5 className="text-center my-3">
                Are you sure you want to delete this category ?
              </h5>

              <div className="d-flex justify-content-center my-3">
                <button
                  type="button"
                  className="btn btn-danger mx-2"
                  onClick={() => {
                    setDeleteIsOpen(false);
                    handleDeleteCategory(selectedCategory);
                    setSelectedCategory(null);
                  }}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="btn btn-secondary mx-2"
                  onClick={() => setDeleteIsOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
