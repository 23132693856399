import usePlacesAutocomplete,{getGeocode,getLatLng} from "use-places-autocomplete";
import {Combobox,ComboboxInput,ComboboxPopover,ComboboxList,ComboboxOption} from '@reach/combobox'
import '@reach/combobox/styles.css'


function SearchPlacesInput({setCenter,setZoom,onSelect}) {
    const {
        ready,
        value,
        setValue,
        suggestions:{status,data},
        clearSuggestions
    } = usePlacesAutocomplete()

    const handleSelect = async(address)=>{
        setValue(address,false)
        clearSuggestions()
        const results = await getGeocode({address})
        const {lat,lng} = await getLatLng(results[0]);
        setCenter({lat,lng})
        onSelect(null,lat,lng)
        setZoom(15)
}


  return (
    <Combobox onSelect={handleSelect}>
        <ComboboxInput 
        value={value} 
        onChange={e=>setValue(e.target.value)} 
        disabled={!ready} 
        className="form-control mt-4 mb-2"
        placeholder="Search an address"
        />
        <ComboboxPopover>
            <ComboboxList>
                {status === 'OK' && data.map(({place_id,description})=><ComboboxOption key={place_id} value={description} />)}
            </ComboboxList>
        </ComboboxPopover>
    </Combobox>

  );
}

export default SearchPlacesInput;
