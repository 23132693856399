export const sortingArr = (arr, type, sortBy) => {
  if (type === "asc") {
    if (sortBy === "email") {
      arr.sort((a, b) => a.User.email.localeCompare(b.User.email));
    } else if (sortBy === "destination") {
      arr.sort((a, b) =>
        a.LOCDestination.destination.localeCompare(b.LOCDestination.destination)
      );
    } else {
      arr.sort((a, b) => a[sortBy].localeCompare(b[sortBy]));
    }
  } else if (type === "desc") {
    if (sortBy === "email") {
      arr.sort((a, b) => b.User.email.localeCompare(a.User.email));
    } else if (sortBy === "destination") {
      arr.sort((a, b) =>
        b.LOCDestination.destination.localeCompare(a.LOCDestination.destination)
      );
    } else {
      arr.sort((a, b) => b[sortBy].localeCompare(a[sortBy]));
    }
  }
  return arr;
};
