import { useState, useEffect } from "react";

import useOrgUsers from "../hooks/gid/useOrgUsers";
import useAssignedUsers from "../hooks/gid/useAssignedUsers";

const useGIDUsersContainer = (orgId,identifierId) => {
  const { loading: usersLoading, users } = useOrgUsers(orgId);
  const { loading: assignedLoading, assignedUsers: data } =
  useAssignedUsers(identifierId);
  const [assignedUsers, setAssignedUsers] = useState([]);

  useEffect(() => {
    if (data) {
      setAssignedUsers(data);
    }
  }, [data]);

  const loading = assignedLoading || usersLoading;

  const onAssignUser = (userId) => {
    if (!assignedUsers.includes(userId)) {
      setAssignedUsers([...assignedUsers, userId]);
    } else {
      setAssignedUsers(assignedUsers.filter((user) => user !== userId));
    }
  };

  return { assignedUsers, onAssignUser, users, loading };
};

export default useGIDUsersContainer;
