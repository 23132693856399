export function separateOriginFromDest(LOCs) {
    const newLOCs = [];

    LOCs.forEach(loc => {
        const commonProps = {
            name: `${loc.actual_asset_name} (${loc.origin_status} - ${loc.LOC_type})`,
            type: loc.LOC_type,
            status: loc.origin_status,
            latitude: loc.origin_latitude,
            longitude: loc.origin_longitude,
            place:'',
            loc_id: loc.loc_id,
            unique_asset_id: loc.unique_asset_id,
            field_1: loc.field_1,
            field_2: loc.field_2,
            field_3: loc.field_3,
        };

        if (loc.LOC_type === 'dual') {
            if(loc?.LOCDestination?.latitude && loc?.LOCDestination?.longitude){

            const originLoc = {
                ...commonProps,
                name: `${loc.actual_asset_name} (${loc.origin_status} - ${loc.LOC_type} - origin)`,
                latitude: loc.origin_latitude,
                longitude: loc.origin_longitude,
                place:'origin',
                id: `${loc.loc_id}-origin`,
            };

            const destinationLoc = {
                ...commonProps,
                name: `${loc.actual_asset_name} (${loc.origin_status} - ${loc.LOC_type} - destination)`,
                latitude: loc?.LOCDestination?.latitude ||0,
                longitude: loc?.LOCDestination?.longitude||0,
                place:'destination',
                id: `${loc.loc_id}-destination`,
            };

            newLOCs.push(originLoc, destinationLoc);
        }

        } else {
            const newLoc = {
                ...commonProps,
                id: loc.loc_id,
            };
            newLOCs.push(newLoc);
        }
    });

    return newLOCs;
}
