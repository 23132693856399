import React, { useEffect, useState } from "react";

import Modal from "react-modal";

import { PropagateLoader } from "react-spinners";
import { css } from "@emotion/react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./AllThirdParties.module.css";
import useThirdPartiesContainer from "../../containers/useThirdPartiesContainer";
import FormikInput from "../formik/FormikInput";
import FormikSelect from "../formik/FormikSelect";
import useOrganizations from "../../hooks/useOrganizations";
import { notAuthError, url } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { DeleteIcon, EditIcon } from "../Icons";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "20px",
  },
};

export default function AllThirdParties() {
  const {
    openForm,
    setOpenForm,
    openDelete,
    setOpenDelete,
    thirdParties,
    isEdit,
    formik,
    handleOpenCreate,
    submitLoading,
    handleOpenDelete,
    handleOpenEdit,
    handleDelete,
    handleSearch,
    loading: thirdPartiesLoading,
  } = useThirdPartiesContainer();

  const [usersInThirdParty, setUsersInThirdParty] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const { token, role } = useSelector(state => state.login);

  useEffect(() => {
    const promises = thirdParties.map(thirdParty => {
      const newUrl = `${url}/api/thirdparties/${thirdParty.id}/users`;
      return fetch(newUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          web: true,
        },
      })
        .then(res => res.json())
        .then(resData => {
          if (resData.error) {
            if (resData.error === notAuthError) {
              toast("Your session expired, please login again.");
              return [];
            } else {
              toast.error(resData.error);
              return [];
            }
          } else {
            let thirdPartyStatus = resData.thirdPartyUsers.some(user => user.suspend === true);
            let thirdPartyWithUsers = {
              id: thirdParty.id,
              suspend: thirdPartyStatus ? thirdPartyStatus : false,
              users: resData.thirdPartyUsers,
            };
            setUsersInThirdParty(prevState => {
              let updatedState = [...prevState];
              if (!updatedState.includes(thirdPartyWithUsers)) {
                updatedState.push(thirdPartyWithUsers);
              }
              return updatedState;
            });
          }
        });
    });
    return Promise.all(promises);
  }, [token, thirdParties]);

  const handleSuspendThirdParty = thirdPartyId => {
    fetch(`${url}/api/thirdparties/${thirdPartyId}/users`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        web: true,
      },
    })
      .then(res => res.json())
      .then(resData => {
        if (resData.error) {
          if (resData.error === notAuthError) {
            toast("Your session expired, please login again.");
            return [];
          } else {
            toast.error(resData.error);
            return [];
          }
        } else {
          resData.thirdPartyUsers.map(user => {
            fetch(`${url}/api/users/${user.user_id}/suspend`, {
              method: "PATCH",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                web: true,
              },
            })
              .then(res => {
                return res.json();
              })
              .then(resData => {
                if (resData.error) {
                  if (resData.error === notAuthError) {
                    toast("you session expired, please login again.");
                    // resolve("error");
                  } else {
                    toast.error(resData.error);
                    // resolve("error");
                  }
                }

                if (resData.error && resData.error[0]) {
                  toast(resData.error[0].message);
                }
                if (resData.message) {
                  toast.success(resData.message);
                  // resolve("success");
                }
              });
          });
          setUsersInThirdParty(
            usersInThirdParty.map(item => (item.id === thirdPartyId ? { ...item, suspend: !item.suspend } : item))
          );
        }
      });
  };

  const { organizations, loading } = useOrganizations();

  if (loading || thirdPartiesLoading)
    return (
      <div
        style={{
          height: "90vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="spinner-border" role="status">
          {/* <span className="sr-only">Loading...</span> */}
        </div>
      </div>
    );

  return (
    <>
      <div className="container">
        <ToastContainer />

        <div className="row">
          <div className="col-12 mt-4  m-auto row">
            <h3 className="my-4 col-6 fs-3 font-semibold text-uppercase" style={{ textTransform: "uppercase" }}>
              Third Parties
            </h3>
            <div className="row col-6 d-flex justify-content-end my-4">
              <div className="col-6 col-md-4 w-100 d-flex justify-content-end">
                <button className="btn btn-primary py-3" onClick={handleOpenCreate}>
                  Add new Third Party
                </button>
              </div>
            </div>
            <div className="w-full m-auto my-4" style={{ position: "relative" }}>
              <i className="far fa-search text-dark" style={{ position: "absolute", top: "12px", left: "2%" }}></i>
              <input
                type="text"
                className="form-control border-1 py-2 border-black rounded-3"
                name="search"
                placeholder="Search"
                onChange={e => {
                  handleSearch(e.target.value);
                  setIsSearch(true);
                }}
                style={{ paddingLeft: "35px" }}
              />
            </div>
          </div>
        </div>

        <div className="row col-12 m-auto">
          <div className="col-12 m-auto pe-0">
            <div className="rounded-t-lg bg-primary py-3 text-white font-semibold">
              <div className="row">
                <div className="col-6 d-flex ps-4">Name</div>
                <div className="col-6 d-flex justify-center ">Actions</div>
              </div>
            </div>
          </div>
        </div>

        <div className="row  col-12 m-auto" style={{ maxHeight: "42vh", overflowY: "scroll", overflowX: "hidden" }}>
          {thirdParties && thirdParties.length > 0 ? (
            thirdParties.map(thirdParty => {
              return (
                <div className="col-12 m-auto pe-0" key={thirdParty.id}>
                  <div className="border py-2">
                    <div className="row">
                      <div className="col-6 d-flex justify-content-start ps-4">
                        <div>{thirdParty.name}</div>
                      </div>

                      <div className="col-6 d-flex justify-content-around ps-4">
                        {role !== "admin" ? (
                          <>
                            <button
                              className={`bg-white no-underline  md:py-2 md:px-4 py-1 px-2 rounded-lg border-2 border-solid ${
                                usersInThirdParty.find(item => item.id === thirdParty.id)?.suspend
                                  ? "border-success text-success"
                                  : "border-danger text-danger"
                              } `}
                              onClick={() => {
                                handleSuspendThirdParty(thirdParty.id);
                              }}
                            >
                              {usersInThirdParty.find(item => item.id === thirdParty.id)?.suspend
                                ? "activate"
                                : "suspend"}
                            </button>
                            <button
                              className="text-white no-underline  md:py-2 md:px-4 py-1 px-2 bg-[#65CC57] rounded-lg border-0 "
                              onClick={e => {
                                e.preventDefault();
                                handleOpenEdit(thirdParty);
                              }}
                            >
                              <EditIcon />
                              <span className="ml-2 hidden md:inline">Edit Third Party</span>
                            </button>

                            <button
                              className="text-white md:py-2 md:px-4 py-1 px-2 bg-[#DC3545] rounded-lg border-0"
                              onClick={e => {
                                e.preventDefault();
                                handleOpenDelete(thirdParty);
                              }}
                            >
                              <DeleteIcon />
                              <span className="ml-2 hidden md:inline">Delete Third Party</span>
                            </button>
                          </>
                        ) : (
                          <div className="text-sm text-gray-400">No Actions Available</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div style={{ textAlign: "center" }}>
              {isSearch ? "third party doesn't exist" : "No Third Parties Added Yet."}
            </div>
          )}
        </div>
      </div>

      <Modal
        isOpen={openForm}
        style={customStyles}
        onRequestClose={() => setOpenForm(false)}
        contentLabel="Example Modal"
        className={styles.Modal}
        overlayClassName={styles.Overlay}
        ariaHideApp={false}
      >
        <div className="container modal-body">
          <div className="row">
            <div className="col-10 m-auto">
              <h5 className="text-center my-3">{`${isEdit ? "Edit" : "Create"} Third Party`}</h5>
              <form onSubmit={formik.handleSubmit}>
                <FormikInput formik={formik} name="name" />
                <FormikSelect formik={formik} name="org_id">
                  <option disabled value="">
                    Select Organization
                  </option>
                  {organizations.map(organization => (
                    <option key={organization.id} value={organization.id}>
                      {organization.name}
                    </option>
                  ))}
                </FormikSelect>
                <div className="d-flex justify-content-center my-3">
                  <button type="submit" className="btn btn-primary w-25 mx-2 hamada" style={{ height: "40px" }}>
                    {submitLoading ? (
                      <PropagateLoader color={"#fff"} css={override} size={10} />
                    ) : isEdit ? (
                      "Save"
                    ) : (
                      "Create"
                    )}
                  </button>
                  <button type="button" className="btn btn-secondary mx-2" onClick={() => setOpenForm(false)}>
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>

      {/* Delete Modal */}
      <Modal
        isOpen={openDelete}
        style={customStyles}
        onRequestClose={() => setOpenDelete(false)}
        contentLabel="Delete Modal"
        className={styles.Modal}
        overlayClassName={styles.Overlay}
        ariaHideApp={false}
      >
        <div className="container modal-body">
          <div className="row">
            <div className="col-10 m-auto">
              <h5 className="text-center my-3">Are you sure you want to delete this third party ?</h5>

              <div className="d-flex justify-content-center my-3">
                <button type="button" className="btn btn-danger mx-2" onClick={handleDelete}>
                  Delete
                </button>
                <button type="button" className="btn btn-secondary mx-2" onClick={() => setOpenDelete(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* End Delete Modal */}
    </>
  );
}
