import React, { Fragment, useState } from "react";
import { useLocation,useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { onLoginHandler } from "../../store/Login/Login";
import logo from "../../assets/images/logo.png";

const styleIcon = {
  position: "absolute",
  top: "12px",
  left: "3%",
  color: "#363636CC",
};

const OTPSignin = () => {

    const dispatch = useDispatch();
    let location = useLocation();
    const navigate = useNavigate();

    const [otp, setOtp] = useState("")
  return (
    <Fragment>
      <div className="container-fluid h-screen">
        <div className="row h-screen">
        <div className="col-md-6 [background:linear-gradient(180deg,_#0b75ad,_#162b46_84.79%,_#162b46)] row align-center justify-center">
              <img src="/splash-logo-13.svg" className="col-7" alt="keltech logo" loading="lazy" />
          </div>
          <div className="col-6 col-md-6 col-lg-4 m-auto ">
            <div className="d-flex justify-content-center ms-2">
              <img style={{ width: "200px", height: "65px" }} src={logo} alt="logo" />
            </div>
            <p
              style={{
                fontSize: "12px",
                paddingLeft: "120px",
                paddingTop: "4px",
              }}
            >
              Smart solutions for smart infrastructure
            </p>

            <h4 className="text-center mt-3">Welcome Back!</h4>
            <p
              className="mt-2"
              style={{ fontSize: "13px", textAlign: "center" }}
            >
              Please enter 2FA sent to your email
            </p>
            <form>
              <div style={{ position: "relative" }}>
                <i className="fas fa-user" style={styleIcon}></i>
                <input
                  type="text"
                  className="form-control"
                  name="OTP"
                  placeholder="2FA"
                  style={{ paddingLeft: "35px" }}
                  onChange={(e) => setOtp(e.target.value)}
                />
              </div>
              <div className="d-flex justify-content-center my-4">
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{ width: "330px", height: "50px" }}
                  onClick={(e) =>
                    dispatch(
                      onLoginHandler(
                        e,
                        location.state.email,
                        location.state.password,
                        navigate,
                        otp
                      )
                    )
                  }
                >
                  Confirm
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default OTPSignin;