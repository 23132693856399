import { locTypes } from "../../../util/constants";
import LOCCheckbox from "./LOCCheckbox";

const LOCsPanel = ({
  hidePanel,
  setHidePanel,
  selectedLocTypes,
  handleSelect,
}) => {
  return (
    <div
      className={`position-absolute ${
        hidePanel ? "h-auto" : "h-45"
      } w-25 bg-white p-1`}
      style={{ border: "2px solid black", bottom: "8px", left: "8px" }}
    >
      <div className="form-group form-check">
        <input
          onClick={() => setHidePanel(!hidePanel)}
          checked={hidePanel}
          type="checkbox"
          className="form-check-input"
          id="hide-panel"
        />
        <label
          className="form-check-label"
          for="hide-panel"
          style={{ fontSize: "12px" }}
        >
          Hide Panel
        </label>
      </div>
      {!hidePanel && <hr className="mb-0" style={{ borderWidth: "1px" }} />}

      {!hidePanel &&
        locTypes.map((locType) => (
          <LOCCheckbox
            key={locType}
            selectedLocTypes={selectedLocTypes}
            handleSelect={handleSelect}
            locType={locType}
          />
        ))}
    </div>
  );
};

export default LOCsPanel;
