import LegendCheckbox from "./LegendCheckbox";

const LegendsPanel = ({
  hidePanel,
  setHidePanel,
  fields,
  selectedLegends,
  handleSelect,
  handleSelectField,
  selectedField,
}) => {
  return (
    <div
      className={`position-absolute ${
        hidePanel ? "h-auto" : "h-45"
      } bg-white p-1`}
      style={{
        border: "2px solid black",
        top: "8px",
        right: "8px",
        width: "250px",
      }}
    >
      <div className="d-flex justify-between items-center py-0 px-2 my-2">
        <div className="form-group form-check">
          <input
            onClick={() => setHidePanel(!hidePanel)}
            checked={hidePanel}
            type="checkbox"
            className="form-check-input"
            id="hide-panel"
          />
          <label
            className="form-check-label"
            for="hide-panel"
            style={{ fontSize: "12px" }}
          >
            Hide Panel
          </label>
        </div>
        <div>
          {!hidePanel && (
            <>
              <select
                class="form-control"
                onChange={(e) => handleSelectField(e.target.value)}
                value={selectedField}
              >
                <option value="">select a field</option>
                <option value="field_1">Field 1</option>
                <option value="field_2">Field 2</option>
                <option value="field_3">Field 3</option>
              </select>
            </>
          )}
        </div>
      </div>
      {!hidePanel && <hr className="mb-0" style={{ borderWidth: "1px" }} />}
      {!hidePanel &&
        selectedField &&
        Object.keys(fields[selectedField]).map((legend, index) => (
          <LegendCheckbox
            index={index}
            legend={legend}
            handleSelect={handleSelect}
            selectedLegends={selectedLegends}
          />
        ))}
    </div>
  );
};

export default LegendsPanel;
