// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCGpNiIhjWXH3ROIjuGr_myDBnJWwJShAo",
  authDomain: "loc-flutter-app.firebaseapp.com",
  projectId: "loc-flutter-app",
  storageBucket: "loc-flutter-app.appspot.com",
  messagingSenderId: "322504802312",
  appId: "1:322504802312:web:bee5187466ce9d8274d1bd",
  measurementId: "G-Q8JK28TWNK"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);