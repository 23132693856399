import React, { Fragment, useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";
import { notAuthError, url } from "../../constants";

import logo from "../../assets/images/logo.png";

const styleIcon = {
  position: "absolute",
  top: "12px",
  left: "3%",
  color: "#363636CC",
};
export default function ForgetPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [user_id, setUser_id] = useState("");
  const [timer, setTimer] = useState(60); 

  const [disableBtn, setDisableBtn] = useState(false);
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      }
    }, 1000);

    return () => clearInterval(interval); 

  }, [timer]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!user_id) {
      fetch(`${url}/api/users/verify-otp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          web: true,
        },
        body: JSON.stringify({
          otp,
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((resData) => {
          if (resData.error) {
            toast(resData.error);
          }
          if (resData.error && resData.error[0]) {
            toast(resData.error[0].message);
          }
          if (resData.message) {
            toast.success(resData.message);
            setTimeout(() => {
              setUser_id(resData.user);
            }, 2000);
          }
        });
    } else {
      fetch(`${url}/api/users/update-password`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          web: true,
        },
        body: JSON.stringify({
          password: password,
          confirmPassword: confirmPassword,
          user_id,
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((resData) => {
          if (resData.error) {
            toast(resData.error);
          }
          if (resData.error && resData.error[0]) {
            toast(resData.error[0].message);
          }
          if (resData.message) {
            toast.success(resData.message);
            setTimeout(() => {
              navigate("/home");
              localStorage.removeItem('loc-email-forget-password')
            }, 2000);
          }
        });
    }
  };

  const handleResendOTP = (e) =>{
    e.preventDefault()
    const email = localStorage.getItem('loc-email-forget-password');
    if(!email){
      toast('something went wrong')
      return;
    }
      fetch(`${url}/api/users/reset-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          web: true,
        },
        body: JSON.stringify({
          email: email,
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((resData) => {
          if (resData.error) {
            if (resData.error === notAuthError) {
              toast("you session expired, please login again.");
            } else {
              toast.error(resData.error);
            }
          }
          if (resData.message) {
            setTimer(60)
            toast.success(resData.message);
          }
        });
  }

  return (
    <Fragment>
      <div className="container">
        <ToastContainer />
        <div className="row mt-5">
          <div className="col-10 col-md-6 col-lg-4 m-auto ">
            <div className="d-flex justify-content-center ms-3">
              <img style={{ width: "140px", height: "60px" }} src={logo} />
            </div>

            <h4 className="text-center mt-3">Welcome Back!</h4>
            <p
              className="mt-2"
              style={{ fontSize: "15px", textAlign: "center" }}
            >
              {!user_id
                ? "We sent an OTP code to your email"
                : "Reset Your Password"}
            </p>
            <form onSubmit={handleSubmit}>
              {!user_id ? (
                <div className="mt-3" style={{ position: "relative" }}>
                  <i className="fas fa-lock" style={styleIcon}></i>
                  <input
                    type="text"
                    className="form-control"
                    name="otp"
                    placeholder="OTP code"
                    style={{ paddingLeft: "35px" }}
                    onChange={(e) => {
                      setOtp(e.target.value);
                    }}
                  />
                </div>
              ) : (
                <>
                  <div className="mt-3" style={{ position: "relative" }}>
                    <i className="fas fa-lock" style={styleIcon}></i>
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      placeholder="Password"
                      style={{ paddingLeft: "35px" }}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </div>
                  <div className="mt-3" style={{ position: "relative" }}>
                    <i className="fas fa-lock" style={styleIcon}></i>
                    <input
                      type="password"
                      className="form-control"
                      name="confirm-password"
                      placeholder="Confirm Password"
                      style={{ paddingLeft: "35px" }}
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                      }}
                    />
                  </div>
                </>
              )}
              <div className="d-flex justify-content-center mt-4 mb-2">
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{ width: "130px" }}
                  onClick={() => {
                    setTimeout(() => {
                      setDisableBtn(true);
                    }, 1);
                    setTimeout(() => {
                      setDisableBtn(false);
                    }, 3000);
                  }}
                >
                  Submit
                </button>
              </div>
              {!user_id &&
               <div className="d-flex justify-center align-items-center">
                {timer>0 ? <>Didn't recieve your OTP Code? you can resend it in {timer} seconds</>:
                 <button className="btn" onClick={handleResendOTP}>Resend OTP</button>}
               </div>
               }
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
