import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { notAuthError, url } from "../../constants";
import { toast } from "react-toastify";

const useAssignedUsers = (projectId) => {
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const { token } = useSelector((state) => state.login);

  useEffect(() => {
    if (projectId) {
      fetch(`${url}/api/projects/` + projectId + "/users", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          web: true,
        },
      })
        .then((res) => res.json())
        .then((resData) => {
          if (resData.error) {
            if (resData.error === notAuthError) {
              toast("you session expired, please login again.");
              setLoading(false);
            } else {
              toast.error(resData.error);
              setLoading(false);
            }
          } else {
            setAssignedUsers(resData.users.map((user) => user.user_id));
            setLoading(false);
          }
        });
    }
  }, [projectId, token]);

  return { assignedUsers, loading };
};

export default useAssignedUsers;
