import React, { useEffect, useRef, useState } from "react";
import Geocode from "react-geocode";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  Circle,
} from "@react-google-maps/api";
import { getArea, getCity, getState } from "../../util/MapsUtil";
import { useDispatch, useSelector } from "react-redux";
import { onChangeProjectInputs } from "../../store/Projects/ProjectsReducer";

import SearchPlacesInput from "./SearchPlacesInput";
Geocode.setApiKey("AIzaSyBW67TD6RvsTX8LfZ2qhRt0Ghdd2DnJL7w");

const containerStyle = {
  width: "100%",
  height: "400px",
};
const libraries = ["places"];

function ProjectsMap({ cmp, radius }) {
  const dispatch = useDispatch();

  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBW67TD6RvsTX8LfZ2qhRt0Ghdd2DnJL7w",
    libraries,
  });
  const { projectForm, selectedEditProject } = useSelector(
    (state) => state.projects
  );

  const [state, setState] = useState({
    address: "",
    city: "",
    area: "",
    state: "",
    mapPosition: {
      lat: 53.349804,
      lng: -6.26031,
    },
    markerPosition: {
      lat: 53.349804,
      lng: -6.26031,
    },
  });

  const [center, setCenter] = useState({
    lat: state.mapPosition.lat,
    lng: state.mapPosition.lng,
  });

  const [zoom, setZoom] = useState(10);

  useEffect(() => {
    Geocode.fromLatLng(state.mapPosition.lat, state.mapPosition.lng).then(
      (response) => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = getCity(addressArray),
          area = getArea(addressArray),
          state = getState(addressArray);

        setState({
          address: address ? address : "",
          area: area ? area : "",
          city: city ? city : "",
          state: state ? state : "",
          mapPosition: {
            lat: 53.349804,
            lng: -6.26031,
          },
          markerPosition: {
            lat: 53.349804,
            lng: -6.26031,
          },
        });
      },
      (error) => {
        console.error(error);
      }
    );
  }, []);

  const onMarkerDragEnd = (event, lat, lng) => {
    let newLat = 0,
      newLng = 0;
    if (event === null) {
      newLat = lat;
      newLng = lng;
    } else {
      newLat = event.latLng.lat();
      newLng = event.latLng.lng();
    }
    Geocode.fromLatLng(newLat, newLng).then(
      (response) => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = getCity(addressArray),
          area = getArea(addressArray),
          state1 = getState(addressArray);
        setState({
          address: address ? address : "",
          area: area ? area : "",
          city: city ? city : "",
          state: state1 ? state1 : "",
          markerPosition: {
            lat: newLat,
            lng: newLng,
          },
          mapPosition: {
            lat: newLat,
            lng: newLng,
          },
        });

        // if (state.mapPosition.lat !== 53.349804) {
        dispatch(onChangeProjectInputs(newLat, "lat"));
        dispatch(onChangeProjectInputs(newLng, "long"));
        setCenter({
          lat: newLat,
          lng: newLng,
        });
        // }
      },
      (error) => {
        console.error(error);
      }
    );
  };
  return (
    <div style={{ width: "100%", margin: "10px 0" }}>
      {!isLoaded && (
        <div className="spinner-border" role="status">
          {/* <span className="sr-only">Loading...</span> */}
        </div>
      )}
      {isLoaded && (
        <>
          <SearchPlacesInput
            setCenter={setCenter}
            setZoom={setZoom}
            onSelect={onMarkerDragEnd}
          />

          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={zoom}
            onClick={(e) => onMarkerDragEnd(e)}
          >
            <Marker
              name={"Dolores park"}
              draggable={true}
              onDragEnd={(e) => onMarkerDragEnd(e)}
              position={{
                lat: state.markerPosition.lat,
                lng: state.markerPosition.lng,
              }}
            />
            <Circle
              center={{
                lat: state.mapPosition.lat,
                lng: state.mapPosition.lng,
              }}
              radius={parseFloat(radius)}
            />
          </GoogleMap>
        </>
      )}
    </div>
  );
}

export default ProjectsMap;
