import React, { Fragment } from "react";
import '../hero/hero.css';
import Logo from '../../assets/images/01_logo-01 1.png';
import Light from '../../assets/images/light effect-04.png';
import goolePlay from '../../assets/images/gooleplay.png';
import appStore from '../../assets/images/appstore.png';
// import keltech from '../../assets/images/keltech logo-01 1.svg'
// import logo from "../../assets/images/LOC.gif";
// import LOClogo from "../../assets/images/loc-logo.png";
// import appleBtn from "../../assets/images/appleBtn.png";
// import googleBtn from "../../assets/images/googleBtn.png";
// import YoutubeEmbed from "../videoView/VideoView";

import { ToastContainer } from "react-toastify";

const Home = () => {
  return (
    <Fragment>
      <ToastContainer />
      <div className='hero-page'>
            <ToastContainer/>
                <div className="container">
                    <div className='top-logo'>
                        <div className='bg-image pb-3 pb-xl-5'>
                            <img src={Light} className='img-fluid' />
                            <div className='logo-con'>
                                <img src={Logo} alt='logo' />
                            </div>
                        </div>
                    </div>
                    <div className='row '>
                        <div className='text-center'>
                            <div className='hero-cont text-center mx-auto'>
                                <h2 className='mb-0'>THE FUTURE</h2>
                                <h4 className='mb-3 mb-sm-3 fs-1'>of asset identification is here</h4>
                                <p className='px-1 px-md-4 my-2 my-sm-3'>LOC is an asset identification software platform that is the reliable, cost-effective solution allowing your business to track all its assets.</p>
                                <div className='d-flex align-items-center justify-content-center'>
                                    <a href='https://apps.apple.com/eg/app/loc-lable-on-a-cable/id1643531827' rel="noreferrer" target='_blank' className='me-3'><img src={appStore} alt='appstore' className='img-fluid' /></a>
                                    <a href='https://play.google.com/store/apps/details?id=com.keltech.loc' rel="noreferrer" target='_blank'><img src={goolePlay} alt='gooleplay' className='img-fluid' /></a>
                                </div>
                                {/* <a className='price-link mt-5 mx-auto' target='_blank' href='https://www.keltechnetworkinnovations.com/products/label-on-cable-loc/'>See Pricing Options</a> */}
                            </div>
                    </div>
                </div>
            </div>
          </div>
      {/* <div className="container-fluid">
        <div className="row mt-5">
          <div className="col-12 col-md-12 col-lg-6 m-auto">
            <div
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{
                  width: "232px",
                  height: "100px",
                  display: "block",
                  margin: "auto",
                }}
                alt="loclogo"
                src={LOClogo}
              />
            </div>
            <h4 className="text-center mt-2">WELCOME TO LABEL ON CABLE</h4>
            <p className="text-center text-lg">
              An Asset Identification Software Platform for efficient,
              cost-effective and reliable tracking of assets
            </p>
            <div className="text-center">
              <a
                rel="noreferrer"
                target="_blank"
                href="https://apps.apple.com/eg/app/loc-lable-on-a-cable/id1643531827"
              >
                <img
                  src={appleBtn}
                  alt="appStore"
                  style={{
                    width: "148px",
                    height: "48px",
                    marginRight: "10px",
                  }}
                />
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.keltech.loc"
              >
                <img
                  src={googleBtn}
                  alt="playStore"
                  style={{
                    width: "148px",
                    height: "48px",
                    marginRight: "10px",
                  }}
                />
              </a>
             
            </div>

            <div className="text-center mt-3">
            <a
                rel="noreferrer"
                target="_blank"
                href="https://www.keltechnetworkinnovations.com/products/label-on-cable-loc/"
              >
                <button type="button" className="btn btn-primary py-2">
                  See Pricing Options
                </button>
              </a>
            </div>

          </div>
          <div className="col-12 col-md-12 col-lg-6">
            <div className="d-flex justify-content-center h-100">
              <YoutubeEmbed embedId="S7yI437M6CU" /> */}
              {/* <img
                src={logo}
                style={{
                  width: "500px",
                  height: "380px",
                  border: "1px solid #0987B1",
                  marginTop: "55px",
                  boxShadow: " 0px 0px 6px #0987B1",
                  opacity: "1",
                  borderRadius: "7px",
                }}
              /> */}
            {/* </div>
          </div>
        </div>
      </div> */}
    </Fragment>
  );
};

export default Home;
