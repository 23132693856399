import React, { Fragment } from "react";
import NavBar from "../../components/navbar/NavBar";
import RecycleBin from "../../components/project/RecycleBin";
import NotAuthPage from "../../pages/NotAuth/NotAuthPage";

import {notSaasAdminOrSuperAdminOrSuperUser} from "../../util/roles";
import { useSelector } from "react-redux";


  const RecycleBinPage = () => {
    const role = useSelector((state) => state.login.role);
  
    if (notSaasAdminOrSuperAdminOrSuperUser.includes(role)) return <NotAuthPage />;
    return (
      <Fragment>
           <NavBar />
           <RecycleBin />
      </Fragment>
      );
  }
  export default RecycleBinPage;