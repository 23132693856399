import { fieldImagesBlack } from "../../../util/constants";

const LegendCheckbox = ({ index, legend, handleSelect, selectedLegends }) => {

  return (
    <div className="form-group form-check">
      <input
        onClick={() => handleSelect(legend)}
        checked={selectedLegends.includes(legend)}
        type="checkbox"
        className="form-check-input"
        id={`loc_checkbox_${legend}`}
      />
      <label className="form-check-label" for={`loc_checkbox_${legend}`} style={{fontSize:'12px'}}>
        {legend}
        <img src={fieldImagesBlack[index]} alt="img" style={{ marginLeft: 10,width:15,height:15 }} />
      </label>
    </div>
  );
};

export default LegendCheckbox;
