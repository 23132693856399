const sorting = (v) => Object.entries(v)
.sort((a, b) => b[1].length - a[1].length)
.slice(0, 10)
.reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
}, {})

export function separateFields(LOCs) {
    const fields = {
        field_1: {},
        field_2: {},
        field_3: {},
    }

    LOCs.forEach(loc => {
        const { field_1, field_2, field_3 } = loc;
        
        if (!fields.field_1[field_1]) {
            fields.field_1[field_1] = [];
        }
        fields.field_1[field_1].push(loc);

        if (!fields.field_2[field_2]) {
            fields.field_2[field_2] = [];
        }
        fields.field_2[field_2].push(loc);

        if (!fields.field_3[field_3]) {
            fields.field_3[field_3] = [];
        }
        fields.field_3[field_3].push(loc);
    });

    const sortedFields = {
        field_1: sorting(fields.field_1),
        field_2: sorting(fields.field_2),
        field_3: sorting(fields.field_3),
    };

    return sortedFields;
}
