import React, { Fragment, useEffect, useState } from "react";
import NavBar from "../../components/navbar/NavBar";
import Logs from "../../components/Logs";
import NotAuthPage from "../../pages/NotAuth/NotAuthPage";
import { notAdmin, notUser } from "../../util/roles";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import fileDownload from "js-file-download";
import { url } from "../../constants";

import {
  onFetchingIdentifierLogs,
  onFetchingSpecificIdentifier,
} from "../../store/LogsReducer/LogsReducer";

const GidLogsPage = () => {
  const role = useSelector((state) => state.login.role);
  const token = useSelector((state) => state.login.token);

  const dispatch = useDispatch();
  const [flag, setFlag] = useState(false);

  const { specificIdnetifier, identifierLogs } = useSelector(
    (state) => state.logs
  );
  const { id } = useParams();

  const handleDownload = () => {
    fetch(`${url}/api/globalIdentifiers/${id}/logs/download`, {
      headers: {
        Authorization: `Bearer ${token}`,
        web: true,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        fileDownload(blob, `${specificIdnetifier.name}.xlsx`);
      });
  };

  useEffect(() => {
    dispatch(onFetchingSpecificIdentifier(id, token));
    dispatch(onFetchingIdentifierLogs(id, token));
  }, [dispatch, token, flag]);

  if (!notAdmin.includes(role)) return <NotAuthPage />;
  if (!notUser.includes(role)) return <NotAuthPage />;

  return (
    <Fragment>
      <NavBar />
      <Logs
        data={identifierLogs}
        name={specificIdnetifier.name}
        handleDownload={handleDownload}
        setFlag={setFlag}
      />
    </Fragment>
  );
};
export default GidLogsPage;
