import React, { Fragment } from "react";
import NavBar from "../../components/navbar/NavBar";
import GlobalSearch from "../../components/global idenetifiers/GlobalSearch";
import NotAuthPage from "../../pages/NotAuth/NotAuthPage";

import {notAdmin} from "../../util/roles";
import { useSelector } from "react-redux";

const GlobalSearchPage = () => {
  const role = useSelector((state) => state.login.role);

  if (!notAdmin.includes(role)) return <NotAuthPage />;
  return (
    <Fragment>
         <NavBar />
         <GlobalSearch />
    </Fragment>
    );
}
export default GlobalSearchPage;