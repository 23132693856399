import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { notAuthError, url } from "../../constants";
import { toast } from "react-toastify";
import { notSuperAdminOrSaasAdminOrAdmin } from "../../util/roles";

const useThirdParty = (orgId, onLoad = true) => {
  const [thirdParties, setThirdParties] = useState([]);
  const [loading, setLoading] = useState(true);
  const { role, token } = useSelector((state) => state.login);

  useEffect(() => {
    if (onLoad && !notSuperAdminOrSaasAdminOrAdmin.includes(role)) {
      const newUrl = orgId
        ? `${url}/api/thirdparties?org_id=${orgId}`
        : `${url}/api/thirdparties`;
      fetch(newUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          web: true,
        },
      })
        .then((res) => res.json())
        .then((resData) => {
          if (resData.error) {
            if (resData.error === notAuthError) {
              toast("you session expired, please login again.");
              setLoading(false);
            } else {
              toast.error(resData.error);
              setLoading(false);
            }
          } else {
            setThirdParties(resData.thirdParties);
            setLoading(false);
          }
        });
    }
  }, [orgId, token, onLoad]);

  return { thirdParties, loading };
};

export default useThirdParty;
